import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import type { BillCategory, BillIssuer } from "../../../../shared/domains/bills/bill";
import { useBillIssuer } from "../../../../shared/domains/bills/use-bill-issuer";
import { collectIssuersWithTopLevelCategory } from "../../../../shared/domains/bills/utils";
import { Path } from "../../../core/routing/path";
import { useRTL } from "../../../domain/language/use-rtl";
import { TertiaryLink } from "../../common/buttons/tertiary-button";
import { ErrorMessage } from "../../common/error-message";
import { SearchBar } from "../../common/forms/search-bar";
import { PageHeader } from "../../common/nav/page-header";
import { PageSectionTitle } from "../../common/nav/page-section-title";
import { MainColorSpinner } from "../../common/spinner";
import { shadows, theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { BillCategoryView } from "./components/bill-category-view";
import { BillIssuerView } from "./components/bill-issuer-view";

export default function BillIssuersScreen() {
  const { formatMessage } = useIntl();
  const { billIssuers, load, loading, refreshing, error, search, setSearch, billIssuerColors } = useBillIssuer();

  const searchedIssuers = useMemo(
    () =>
      billIssuers && search
        ? collectIssuersWithTopLevelCategory(billIssuers).filter(
            (billIssuer) => billIssuer.name && billIssuer.name.toLowerCase().includes(search.toLowerCase()),
          )
        : null,
    [billIssuers, search],
  );

  const colors = useMemo(
    () =>
      new Map<string, string>(
        billIssuers
          ? billIssuers
              .filter((issuerOrCategory) => issuerOrCategory.type === "CATEGORY")
              .map((category, index) => [category.id, billIssuerColors[index % billIssuerColors.length]])
          : null,
      ),
    [billIssuers, billIssuerColors],
  );

  const { isRTL } = useRTL();

  useEffect(() => {
    load();
  }, []);

  return (
    <BillIssuerSection>
      <PageHeader>
        <BillIssuerSectionTitle $isRTL={isRTL}>{formatMessage("billIssuersScreen.title")}</BillIssuerSectionTitle>
        <GoToMyReferencesLink to={Path.References} $isRTL={isRTL}>
          {formatMessage("billIssuersScreen.references")}
        </GoToMyReferencesLink>
        <SearchBar initialValue={search || ""} onChange={(text) => setSearch(text)} />
      </PageHeader>
      {billIssuers && billIssuers.length > 0 ? (
        searchedIssuers ? (
          searchedIssuers.map((issuer) => (
            <StyledBillIssuerView
              issuer={issuer}
              key={issuer.id}
              color={colors.get(issuer.topLevelCategory?.id ?? "") || "#000000"}
              topLevelCategory={issuer.topLevelCategory}
            />
          ))
        ) : (
          <BillIssuerRenderer issuers={billIssuers} colors={colors} isTopLevel />
        )
      ) : loading || refreshing ? (
        <StyledSpinner />
      ) : error ? (
        <StyledErrorMessage>{formatMessage("billIssuersScreen.loadBillIssuersError")}</StyledErrorMessage>
      ) : (
        <EmptyListMessage>{formatMessage("billIssuersScreen.emptyListLabel")}</EmptyListMessage>
      )}
    </BillIssuerSection>
  );
}

const BillIssuerRenderer: React.FC<{
  issuers: (BillIssuer | BillCategory)[];
  colors: Map<string, string>;
  isTopLevel?: boolean;
  topLevelCategory?: BillCategory;
}> = ({ issuers, colors, isTopLevel, topLevelCategory }) => (
  <>
    {issuers.map((issuerOrCategory) =>
      issuerOrCategory.type === "CATEGORY" ? (
        <BillCategoryView
          key={issuerOrCategory.id}
          category={issuerOrCategory}
          color={colors.get(issuerOrCategory.id) || "#000000"}
          isTopLevel={isTopLevel}
        >
          <BillIssuerRenderer
            issuers={issuerOrCategory.children}
            colors={colors}
            topLevelCategory={topLevelCategory || issuerOrCategory}
          />
        </BillCategoryView>
      ) : isTopLevel ? (
        <StyledBillIssuerView
          key={issuerOrCategory.id}
          issuer={issuerOrCategory}
          color={colors.get(topLevelCategory?.id ?? "") || "#000000"}
          topLevelCategory={topLevelCategory}
        />
      ) : (
        <BillIssuerView
          key={issuerOrCategory.id}
          issuer={issuerOrCategory}
          color={colors.get(topLevelCategory?.id ?? "") || "#000000"}
          topLevelCategory={topLevelCategory}
        />
      ),
    )}
  </>
);

const BillIssuerSection = styled.section`
  align-self: stretch;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BillIssuerSectionTitle = styled(PageSectionTitle)<{ $isRTL: boolean }>`
  margin-right: ${(props) => (props.$isRTL ? 0 : 10)}px;
  margin-left: ${(props) => (props.$isRTL ? 10 : 0)}px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 30px;
`;

const EmptyListMessage = styled.span`
  ${theme.mediumText}
  font-size: 0.875rem;
  color: #b1b1b1;
  padding: 0 20px;
  margin-top: 30px;
  text-align: center;
`;

const StyledSpinner = styled(MainColorSpinner)`
  margin: 40px auto 0 auto;
`;

const StyledBillIssuerView = styled(BillIssuerView)`
  margin: 10px 0;
  border-radius: 10px;
  ${shadows.medium};
  background-color: #ffffff;
`;

const GoToMyReferencesLink = styled(TertiaryLink)<{ $isRTL: boolean }>`
  text-align: center;
  margin-right: ${(props) => (props.$isRTL ? 0 : 10)}px;
  margin-left: ${(props) => (props.$isRTL ? 10 : 0)}px;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-bottom: 20px;
  }
`;
