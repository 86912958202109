import type { HTMLAttributes } from "react";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import type { Amount } from "../../../../../shared/core/amount/amount";
import { Config } from "../../../../../shared/core/config/config";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { featuresManager } from "../../../../../shared/core/service/services";
import type { Account } from "../../../../../shared/domains/account/account";
import { AccountingEntryType } from "../../../../../shared/domains/accounting-transaction/accounting-entry";
import type { AccountingTransaction } from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import { useSpendings } from "../../../../../shared/domains/accounting-transaction/transaction/use-spendings";
import { useTransactionLinks } from "../../../../../shared/domains/accounting-transaction/use-transaction-links";
import useCurrencies from "../../../../../shared/domains/currencies/use-currencies";
import { useObservable } from "../../../../../shared/utils/observable";
import {
  ACCOUNT_TRANSACTION_DETAILS_MODAL_ID,
  REFUND_TRANSACTION_REASON_MODAL_ID,
} from "../../../../core/modal/modal-id";
import { Path } from "../../../../core/routing/path";
import { useClient } from "../../../../domain/authentication/use-client";
import { useRTL } from "../../../../domain/language/use-rtl";
import { AmountText } from "../../../common/amount-text";
import { GrayPrimaryButton, PrimaryButton } from "../../../common/buttons/primary-button";
import { MapIframe } from "../../../common/map-iframe";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { theme } from "../../../styles/theme";
import { RefundTransactionReasonModal } from "./refund-transaction-reason-modal.tsx";

interface AccountTransactionDetailsProps {
  transaction: AccountingTransaction;
  account: Account;
}

const accountEntryLabels = {
  [AccountingEntryType.Transfer]: "accountingTransactionDetailsModal.transferLabel",
  [AccountingEntryType.Fee]: "accountingTransactionDetailsModal.feeLabel",
  [AccountingEntryType.Commission]: "accountingTransactionDetailsModal.commissionLabel",
  [AccountingEntryType.Tax]: "accountingTransactionDetailsModal.taxLabel",
  [AccountingEntryType.Discount]: "accountingTransactionDetailsModal.discountLabel",
  [AccountingEntryType.Interest]: "accountingTransactionDetailsModal.interestLabel",
};

export const AccountTransactionDetailsModal = (props: AccountTransactionDetailsProps) => (
  <RoundedModalContainer closeButton id={ACCOUNT_TRANSACTION_DETAILS_MODAL_ID}>
    <AccountTransactionDetails transaction={props.transaction} account={props.account} />
  </RoundedModalContainer>
);

const AccountTransactionDetails: React.FC<AccountTransactionDetailsProps> = ({ transaction, account }) => {
  const { formatMessage, formatDate } = useIntl();
  const { canJustify, canEditJustification, canViewJustification } = useTransactionLinks();
  const { push } = useHistory();
  const { client } = useClient();
  const { amountJustified, spendings } = useSpendings(transaction);

  const features = useObservable(featuresManager.features);

  const detailedAccountingTransfer = transaction?.detailedAccounting?.find(
    (accounting) => accounting.type === AccountingEntryType.Transfer,
  );
  const transactionDescription =
    transaction.shortDescription || detailedAccountingTransfer?.description || transaction.type.label;

  const remittanceInformation = transaction.externalData?.remittanceInformation;

  if (!transaction.amount) {
    return null;
  }

  const handleJustifyTransaction = () => {
    push(Path.JustifyTransaction, { transaction, account });
    Modal.dismiss(ACCOUNT_TRANSACTION_DETAILS_MODAL_ID);
  };

  /**
   * This is a temporary solution to hide the refund button.
   * The feature must be hidden until the api handles this type of request (by implementing a step of bank validation)
   * Original ticket: https://tagpay.atlassian.net/browse/VC-563
   * Dev Note: TODO remove this const variable when the feature is enabled.
   */
  const displayRefundButton = false;

  return (
    <Container>
      <Header>
        <Title>{transactionDescription}</Title>
      </Header>
      <DateLabel>
        {formatDate(transaction.date, {
          month: "long",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </DateLabel>
      <Description>{transaction.description}</Description>
      {!!transaction.label && (
        <Description style={{ marginTop: 10 }}>
          {formatMessage("accountingTransactionDetailsModal.description", { description: transaction.label })}
        </Description>
      )}
      {!!transaction.id && (
        <Description style={{ marginTop: 10 }}>
          {formatMessage("accountingTransactionDetailsModal.transaction", { id: transaction.id })}
        </Description>
      )}
      {remittanceInformation && (
        <Description style={{ marginTop: 10 }}>
          {formatMessage("accountingTransactionDetailsModal.reference", { id: remittanceInformation })}
        </Description>
      )}
      <DetailsTitle>{formatMessage("accountingTransactionDetailsModal.detailsTitle")}</DetailsTitle>
      <Items>
        {transaction.detailedAccounting.map((value, index) => (
          <TransactionEntryView
            key={value.id}
            label={formatMessage(accountEntryLabels[value.type])}
            amount={value.amount}
            bold={transaction.detailedAccounting.length === 1}
            showExchangeRate={transaction.detailedAccounting.length === 1}
            hasLink
          />
        ))}
        {(!transaction.detailedAccounting || transaction.detailedAccounting.length > 1) && (
          <TransactionEntryView
            label={formatMessage("accountingTransactionDetailsModal.amountLabel")}
            amount={transaction.amount}
            hasLink
            bold
            showExchangeRate
          />
        )}
        <TransactionEntryView
          label={formatMessage("accountingTransactionDetailsModal.balanceLabel")}
          amount={transaction.balance}
          green
        />
      </Items>
      {spendings.length > 0 ? (
        <>
          <DetailsTitle>{formatMessage("accountingTransactionDetailsModal.justificationFollowUpTitle")}</DetailsTitle>
          <Items>
            <TransactionEntryView
              label={formatMessage("accountingTransactionDetailsModal.documentsSubmitted")}
              amount={amountJustified()}
              hasLink={canEditJustification(transaction)}
              bold
            />
            {canEditJustification(transaction) ? (
              <>
                <TransactionEntryView
                  label={formatMessage("accountingTransactionDetailsModal.validationInProgress")}
                  amount={null}
                  grey
                />
              </>
            ) : null}
          </Items>
        </>
      ) : null}
      {transaction.geolocation && (
        <>
          <Location>{formatMessage("accountingTransactionDetailsModal.geolocationTitle")}</Location>
          <MapIframe location={transaction.geolocation} allowFullScreen />
        </>
      )}
      {displayRefundButton &&
        transaction.businessProcessId &&
        transaction.type?.value === "ACCOUNT_TO_EXTERNAL" &&
        features.customerInstructionSubmit &&
        features.customerInstructionView &&
        features.paymentInstructionView && (
          <CanRefundButton
            onClick={() => {
              Modal.present(
                REFUND_TRANSACTION_REASON_MODAL_ID,
                () => <RefundTransactionReasonModal transaction={transaction} client={client} />,
                {
                  canBeDismissed: false,
                },
              );
            }}
            size="S"
          >
            {formatMessage("accountingTransactionDetailsModal.refundButtonLabel")}
          </CanRefundButton>
        )}
      {(canViewJustification(transaction) || canJustify(transaction)) && (
        <CanJustifyButton onClick={handleJustifyTransaction} size="S">
          {formatMessage(
            canJustify(transaction)
              ? "accountingTransactionDetailsModal.justifyButtonLabel"
              : "accountingTransactionDetailsModal.verifyJustificationsButtonLabel",
          )}
        </CanJustifyButton>
      )}
    </Container>
  );
};

interface TransactionEntryViewProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  amount: Amount | undefined;
  green?: boolean;
  grey?: boolean;
  hasLink?: boolean;
  bold?: boolean;
  showExchangeRate?: boolean;
}

export const TransactionEntryView: React.FC<TransactionEntryViewProps> = (props) => {
  const { label, amount, green, grey, hasLink, bold, showExchangeRate, ...viewProps } = props;
  const { formatMessage } = useIntl();
  const { isRTL } = useRTL();
  const { canShowExchangeRate, convertedAmount } = useCurrencies(
    amount,
    Config.CURRENCY_TO_CONVERT_IN_EUR_ENABLED ? "EUR" : null,
  );

  return (
    <Item {...viewProps}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Bullet green={green} grey={grey} $isRTL={isRTL} />
        <div>
          <ItemLabel grey={grey} bold={bold}>
            {label}
          </ItemLabel>
          {canShowExchangeRate && showExchangeRate && convertedAmount && (
            <ItemLabel style={{ ...theme.bodyGreyRegular }}>
              {formatMessage("amountSelection.convertedAmountLabel")}
            </ItemLabel>
          )}
        </div>
      </div>
      {amount !== null ? (
        <ItemValue green={green} bold={bold}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            {amount && <AmountText amount={amount} />}
            {canShowExchangeRate && showExchangeRate && convertedAmount && (
              <AmountText
                style={{
                  ...theme.bodyGreyRegular,
                }}
                amount={convertedAmount}
              />
            )}
          </div>
        </ItemValue>
      ) : null}
      {hasLink && <Link $isRTL={isRTL} />}
    </Item>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  ${theme.boldText};
  font-size: 1.375rem;
  margin: 0;
  flex-shrink: 1;
  flex-grow: 1;
`;

const DetailsTitle = styled.h3`
  ${theme.boldText};
  font-size: 1.125rem;
  margin: 4px 0;
`;

const DateLabel = styled.span`
  ${theme.text};
  font-size: 0.9375rem;
  margin: 20px 0;
`;

const Description = styled.span`
  ${theme.text};
  color: #b1b1b1;
  font-size: 0.9375rem;
  margin: 10px 0;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const ItemLabel = styled.span<{ bold?: boolean; green?: boolean; grey?: boolean }>`
  ${(props) => (props.bold ? theme.boldText : theme.text)};
  display: block;
  color: ${(props) => (props.green ? "#2ed794" : props.grey ? "#b1b1b1" : "#000000")};
  flex-shrink: 1;
  flex-grow: 1;
`;

const ItemValue = styled.span<{ bold?: boolean; green?: boolean }>`
  ${(props) => (props.bold ? theme.boldText : theme.text)};
  color: ${(props) => (props.green ? "#2ed794" : "#000000")};
  margin-left: 20px;
`;

const Bullet = styled.span<{ green?: boolean; grey?: boolean; $isRTL: boolean }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => (props.green ? "#2ed794" : props.grey ? "#b1b1b1" : "#000000")};
  margin-right: ${(props) => (props.$isRTL ? 0 : 15)}px;
  margin-left: ${(props) => (props.$isRTL ? 15 : 0)}px;
  border-radius: 15px;
`;

const Items = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Link = styled.div<{ $isRTL: boolean }>`
  position: absolute;
  top: 100%;
  left: ${(props) => (props.$isRTL ? "unset" : "3.5px")};
  right: ${(props) => (props.$isRTL ? "3.5px" : "unset")};
  width: 2px;
  height: 20px;
  border-radius: 1.5px;
  background-color: #000000;
`;

const Location = styled(DetailsTitle)`
  margin-top: 30px;
`;

const CanJustifyButton = styled(PrimaryButton)`
  margin-top: 20px;
`;

const CanRefundButton = styled(GrayPrimaryButton)`
  margin-top: 35px;
`;
