import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";

type FormLabelProps = {
  label: string;
  required?: boolean;
  htmlFor?: string;
  disableFormatting?: boolean;
};

export default function FormLabel(props: FormLabelProps) {
  const { htmlFor, label, required, disableFormatting } = props;
  const { formatMessage } = useIntl();
  return (
    <Label htmlFor={htmlFor}>
      {disableFormatting ? label : formatMessage(label)}
      {required ? " *" : ""}
    </Label>
  );
}

const Label = styled.label`
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  margin-bottom: 8px;
`;
