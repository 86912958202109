import { logger } from "../../core/logging/logger";
import type { SavingsApiService } from "../../core/net/savings-api-service";
import { Observable } from "../../utils/observable";
import type { SavingDetails, TaxStatement } from "./savings";

export class SavingsService {
  public constructor(private apiService: SavingsApiService) {}
  public token = new Observable<string>("");

  public async getSavingsDetails(cbsAccountId): Promise<SavingDetails> {
    try {
      const response = await this.apiService.instance.get(`/contracts/by-account-number/${cbsAccountId}`);
      return response.data;
    } catch (e) {
      logger.debug("Savings Service", "get savings failed", e);
      throw e;
    }
  }

  public async getTaxStatements(userId: string): Promise<TaxStatement[]> {
    try {
      const response = await this.apiService.instance.get(`/user/${userId}/tax-statements`);
      return response.data;
    } catch (e) {
      logger.debug("Savings Service", "get tax statements failed", e);
      throw e.response;
    }
  }

  public async downloadTaxStatement(documentId: string): Promise<any> {
    //TODO: update type here when the second part of this dev is done (vc-1086)
    try {
      const response = await this.apiService.instance.get(`/documents/${documentId}/content`);
      return response.data;
    } catch (e) {
      logger.debug("Savings Service", "retrieve tax statement content failed", e);
      throw e;
    }
  }
}
