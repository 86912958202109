import type { HTMLAttributes } from "react";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { featuresManager } from "../../../../shared/core/service/services";
import { ClientStatus, useClientDisplayName } from "../../../../shared/domains/client/client";
import { useObservable } from "../../../../shared/utils/observable";
import { usePromptPincodeChangeAfterAuthentication } from "../../../core/lifecycle/use-prompt-pincode-after-authentication";
import { PINCODE_MUST_BE_CHANGED_MODAL_ID } from "../../../core/modal/modal-id";
import { Path } from "../../../core/routing/path";
import { useClient } from "../../../domain/authentication/use-client";
import { useRTL } from "../../../domain/language/use-rtl";
import { OnboardingFileDelegateErrorStatusCard } from "../../screen/enrollment/onboarding/components/onboarding-file-delegate-error-status-card";
import { OnboardingFileStatusCard } from "../../screen/enrollment/onboarding/components/onboarding-file-status-card";
import { PincodeChangeModal } from "../../screen/pincode/pincode-change-modal";
import { theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { DefaultButton, DefaultLink } from "../buttons/default-button";
import { ErrorMessage } from "../error-message";
import { MarkdownText } from "../forms/markdown-text";
import { Modal } from "../modal/modal";
import { ResponsiveContainer } from "../responsive-container";
import { Nav } from "./side-nav";

export interface PageWithNaveProps extends HTMLAttributes<HTMLDivElement> {
  withTitle?: boolean;
}

export function PageWithNav(props: PageWithNaveProps) {
  const { formatMessage } = useIntl();
  const { client, loading } = useClient();
  const location = useLocation();
  const { isRTL } = useRTL();
  const { withTitle = true } = props;
  const features = useObservable(featuresManager.features);

  usePromptPincodeChangeAfterAuthentication();
  const displayName = useClientDisplayName(client, true);

  if (!loading && !client && features.clientInformationView) {
    return <Redirect to={{ pathname: Path.Root }} />;
  }
  const onOnboardingFilePage = location.pathname === Path.OnboardingFile;

  return (
    <Page>
      <>
        {!onOnboardingFilePage && <Nav />}
        <Content withNav={!onOnboardingFilePage} $isRTL={isRTL}>
          <>
            {!onOnboardingFilePage && (
              <>
                {withTitle && (
                  <WelcomeTitle>
                    {displayName
                      ? formatMessage("connectedUserGreetings.withName", { name: displayName })
                      : formatMessage("connectedUserGreetings.withoutName")}
                  </WelcomeTitle>
                )}
                <AlertContainer>
                  {client?.status === ClientStatus.Pending || client?.status === ClientStatus.Initiated ? (
                    <InformationMessage>{formatMessage("clientStatus.clientPendingMessage")}</InformationMessage>
                  ) : null}
                  {client?.status === ClientStatus.Blocked || client?.status === ClientStatus.Iniblocked ? (
                    <ErrorMessageWrapper>
                      <ErrorMessage>{formatMessage("clientStatus.clientBlockedMessage")}</ErrorMessage>
                      {location.pathname !== Path.AccountBlocked &&
                        location.pathname !== Path.AccountBlockedAfterPincodeError && (
                          <MoreLink to={Path.AccountBlocked} $isRTL={isRTL}>
                            {formatMessage("clientStatus.clientBlockedLink")}
                          </MoreLink>
                        )}
                    </ErrorMessageWrapper>
                  ) : null}
                  {client?.mustChangePincode ? (
                    <InformationMessage>
                      {formatMessage("pincodeChangeScreen.messageAfterAuthentication") + " "}
                      <InlineButton
                        onClick={() =>
                          Modal.present(
                            PINCODE_MUST_BE_CHANGED_MODAL_ID,
                            () => <PincodeChangeModal type="after-authentication" />,
                            {
                              canBeDismissed: false,
                            },
                          )
                        }
                      >
                        {formatMessage("pincodeChangeScreen.changePincodeButton")}
                      </InlineButton>
                    </InformationMessage>
                  ) : null}
                  <OnboardingFileDelegateErrorStatusCard />
                  <OnboardingFileStatusCard />
                </AlertContainer>
              </>
            )}
            {props.children}
          </>
        </Content>
      </>
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 15vh;
  width: 100%;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
    flex-direction: column;
  }
`;

const Content = styled(ResponsiveContainer)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const WelcomeTitle = styled(MarkdownText)`
  font-size: 1.875rem;
  ${theme.boldText};
  margin-bottom: 40px;
  color: #000000;
  white-space: pre;
`;

const AlertContainer = styled.div<{ children?: React.ReactNode }>`
  ${(props) => (props.children ? "margin-bottom: 40px;" : null)};
`;

const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  margin-bottom: 40px;
`;

const MoreLink = styled(DefaultLink)<{ $isRTL: boolean }>`
  margin-left: ${(props) => (props.$isRTL ? 0 : 10)}px;
  margin-right: ${(props) => (props.$isRTL ? 10 : 0)}px;

  font-size: 0.875rem;
  ${theme.mediumText};
  text-decoration: underline;
  color: #000;
`;

const InlineButton = styled(DefaultButton)`
  display: inline-block;
  font-size: 0.875rem;
  ${theme.mediumText};
  padding: 0px 0px 0px 0px;
  text-decoration: underline;
`;

const InformationMessage = styled(ErrorMessage)`
  margin-top: -20px;
  margin-bottom: 20px;
  color: #000;
`;
