import { Observable } from "./observable";
import type { Paginated } from "./pagination";

export class ObservablePaginated<T> extends Observable<Paginated<T>> {
  public add(page: Paginated<T>) {
    const data = {
      items: [...this.get().items, ...page.items],
      count: page.count,
      total: page.total,
      offset: page.offset,
      limit: page.limit,
      sort: page.sort,
    };
    this.set(data);
  }
}
