{
	"TRANSACTION_WITHDRAW_CODE_GENERATION_ERROR": "No se permite el retiro para este punto de transacción",
	"common": {
		"today": "Hoy",
		"yesterday": "Ayer",
		"goBack": "Volver",
		"languages": {
			"fr_FR": "Fran\u00e7ais (France)",
			"en_US": "English (USA)",
			"es_ES": "Espa\u00f1ol (Espa\u00f1a)"
		},
		"autocompletePicker": {
			"placeholder": "Seleccionar...",
			"searchPlaceholder": "Escriba al menos 3 caracteres para buscar...",
			"noResults": "No hay resultados"
		},
		"invalidPhoneNumber": "El n\u00famero de tel\u00e9fono no es v\u00e1lido",
		"yes": "S\u00ed",
		"no": "No",
		"cancel": "Cancelar"
	},
	"authScreen": {
		"welcome": "Bienvenido a su portal bancario",
		"login": "Conectarse",
		"createAccount": "Crear una cuenta",
		"appstoreLink": "Appstore",
		"playstoreLink": "Playstore",
		"goToCompanyPortalQuestion": "Ud. es una empresa?",
		"goToCompanyPortalLink": "Portal para las empresas",
		"goToPartnersPortalQuestion": "Ud. es comerciante?",
		"goToPartnersPortalLink": "Portal para comerciantes",
		"goToAgentPortalQuestion": "Ud. es un agente?",
		"goToAgentPortalLink": "Portal para agentes",
		"storesAvailable": "Disponible también con",
		"disconnectedDueToInactivity": "Desconectado por inactividad",
		"authenticationFailure": {
			"title": "Error de autenticaci\u00e9n"
		}
	},
	"connectScreen": {
		"title": "Bienvenido a [emph]Skaleet[/emph]",
		"subtitle": "Por favor ingrese su número de teléfono para conectar",
		"lostPhoneButton": "¿Olvidó el número de teléfono?",
		"connectButton": "Conectar",
		"registerQuestion": "¿No tienes una cuenta?",
		"registerButton": "Crear una cuenta",
		"errorTitle": "Un error ocurri\u00f3",
		"noAccountError": "No pudimos encontrar una cuenta para {phoneNumber}. Puede crear una cuenta para iniciar sesi\u00f3n.",
		"pincodeTitle": "Código [emph]secreto[/emph]",
		"otpTitle": "Verificación de [emph]dos pasos[/emph]",
		"noCodeQuestion": "Código no recibido?",
		"contactSupportLink": "Por favor contacte soporte.",
		"contactSupport": {
			"title": "¿Ya no tienes [emph]acceso al tel\u00e9fono[/emph] vinculado a tu cuenta?",
			"message": "Para cambiar sus datos, comun\u00edquese con nuestro equipo de soporte que le dir\u00e1 qu\u00e9 hacer.",
			"byPhone": "Cont\u00e1ctanos por tel\u00e9fono",
			"byEmail": "Cont\u00e1ctanos por correo electr\u00f3nico",
			"done": "Terminar"
		}
	},
	"errorScreen": {
		"subTitle": "Demasiadas solicitudes",
		"title": "El intento de conexión falló",
		"message": "No podemos procesar su solicitud de conexión en este momento.\n\n Por favor, inténtelo de nuevo más tarde.",
		"button": "Volver a la página de inicio de sesión"
	},
	"onboardingStatusCard": {
		"title": "Mas informaci\u00f3n es requerida para su expediente",
		"subtitle": "Lorem ipsum dolor sit amet",
		"button": "Completa mi expediente"
	},
	"onboardingDelegateErrorStatusCard": {
		"title": "No se puede acceder al formulario de registro como usuario delegado. Las funciones disponibles son limitadas. El titular de la cuenta debe completar su registro."
	},
	"onboardingScreen": {
		"progressLabel": "Etapa {step} de {count}: {label}",
		"initialStepTitle": "Bienvenido a [emph]MWallet.[/emph]",
		"resumptionStepTitle": "Cu\u00e9ntanos m\u00e1s sobre [emph]ti[/emph]",
		"successIntroduction": "Felicidades",
		"successTitle": "Su cuenta ha sido creada con \u00e9xito",
		"successButton": "Comenzar",
		"initiationSuccessTitle": "Su cuenta ha sido creada con \u00e9xito.",
		"initiationSuccessButton": "Comenzar",
		"initiationSuccessMsg": "Un c\u00f3digo secreto ha sido enviado a {phone}",
		"getStartedButton": "Comenzar",
		"cancelButton": "Cancelar",
		"back": "Anterior",
		"next": "Siguiente",
		"save": "Guardar",
		"submit": "Enviar",
		"taxedNumberError": {
			"undefined": "Error: Ingrese un número de teléfono válido.",
			"PREMIUM_RATE": "Error: Los numeros de tarifa premium no se pueden utilizar para registrar una cuenta. Proporcione un numero de linea fija o movil.",
			"TOLL_FREE": "Error: los numeros gratuitos no son compatibles para la verificacion de la cuenta. Utilice un numero de telefono fijo o movil estandar.",
			"SHARED_COST": "Error: Los numeros con facturacion de costos compartidos no estan permitidos para la creacion de cuentas. Por favor ingrese un numero de línea fija o movil.",
			"VOIP": "Error: los numeros de telefono basados en Internet no son elegibles para el registro. Utilice un numero de linea fija o movil estandar.",
			"PERSONAL_NUMBER": "Error: No se aceptan numeros personales conectados via satelite. Utilice un numero de telefono fijo o movil.",
			"PAGER": "Error: Los dispositivos buscapersonas no son validos para la creacion de cuentas. Proporcione un numero de linea fija o movil aceptado.",
			"UAN": "Error: Los numeros de acceso universal (UAN) no se pueden utilizar para el registro. Ingrese un numero de linea fija o movil valido.",
			"VOICEMAIL": "Error: No se pueden utilizar numeros dedicados a servicios de correo de voz. Ingrese un numero de linea fija o movil que pueda recibir llamadas directamente."
		},
		"formLabels": {
			"selectDistributorDuringOnboarding": "Código de distribuidor",
			"selectDistributorDuringOnboardingHelperText": "Por favor, introduzca el ID proporcionado por su referente de tarjeta.",
			"firstName": "Nombre",
			"lastName": "Apellido",
			"phoneNumber": "N\u00famero de tel\u00e9fono",
			"email": "Correo electr\u00f3nico"
		}
	},
	"registerScreen": {
		"progressLabel": "Etapa {step} de {count}: {label}",
		"firstStepTitle": "\u00a1Bienvenido!",
		"firstStepSubtitle": "Por favor ingrese su número de teléfono para registrarse",
		"connectQuestion": "¿Ya tienes una cuenta?",
		"connectButton": "Conectarse",
		"phoneNumberLabel": "N\u00famero de tel\u00e9fono",
		"back": "Anterior",
		"next": "Siguiente",
		"finish": "Terminar",
		"otpTitle": "Verificación del tel\u00e9fono m\u00f3vil",
		"otpLabel": "Por favor ingrese el c\u00f3digo enviado a su tel\u00e9fono m\u00f3vil",
		"nbPicturesForDocument": {
			"one": "al menos una foto",
			"others": "al menos {count} fotos"
		},
		"pictureInputFilled": "Un archivo",
		"pictureInputEmpty": "Elegir or soltar una foto",
		"addPictureButton": "Añadir una pagina",
		"eulaTitle": "Condiciones de uso",
		"eulaFirstDescription": "Acepte las condiciones de uso para crear una cuenta.",
		"eulaSecondDescription": "Los encontrará haciendo clic en el enlace siguiente:",
		"eulaLink": "Condiciones de uso",
		"eulaAcceptLabel": "Acepto las condiciones de uso",
		"createMyAccountButton": "Crear mi cuenta",
		"finishTitleLabel": "Felicidades !",
		"finishLabel": "Su cuenta ha sido creada satisfactoriamente.",
		"pincodeLabel": "Su c\u00f3digo",
		"pincodeStepTitle": "Su [emph]c\u00f3digo.[/emph]",
		"message": "Debe elegir un c\u00f3digo secreto de cuatro d\u00edgitos para su cuenta. Sobre todo, no se lo comunique a nadie, es estrictamente confidencial.",
		"doNotForgetWarning": "Cuidado, aseg\u00farate de conservarlo: lo necesitar\u00e1s para autenticarte y validar todas tus transacciones.",
		"successSnackbar": "Su c\u00f3digo ha sido validado",
		"newCodeLabel": "Ingrese su nuevo c\u00f3digo",
		"quitConfirmMessage": "Está a punto de salir del registro. Se perderán todos los datos rellenados. ¿Estás seguro de que quieres salir de la página?",
		"cancel": "Cancelar",
		"newCodeConfirmationLabel": "Confirme su nuevo c\u00f3digo",
		"newCodesDontMatchError": "Los c\u00f3digos ingresados no corresponden",
		"noEulaTitle": "Confirmaci\u00f3n de la creaci\u00f3n de la cuenta",
		"noEulaFirstDescription": "¡Ya casi ha terminado!",
		"noEulaSecondDescription": "Al hacer clic en el botón de abajo, la creación de su cuenta será efectiva. ¿Seguimos?",
		"privacyTitle": "Politica de confidencialidad",
		"privacyFirstDescription": "Acepte la politica de privacidad para crear su cuenta.",
		"privacySecondDescription": "Los encontrara haciendo clic en el enlace siguiente:",
		"privacyLink": "Politica de confidencialidad",
		"privacyAcceptLabel": "Acepto la politica de confidencialidad",
		"noPrivacyTitle": "Politica de confidencialidad",
		"noPrivacyFirstDescription": "¡Ya casi ha terminado!",
		"noPrivacySecondDescription": "Al hacer clic en el botón de abajo, la creación de su cuenta será efectiva. ¿Seguimos?"
	},
	"accountOwner": {
		"male": "Sr. {name}",
		"female": "Sra. {name}",
		"other": "{name}",
		"none": "{name}"
	},
	"connectedUserGreetings": {
		"withName": "¡Hola,\n[emph]{name}[/emph]!",
		"withoutName": "¡Hola!"
	},
	"clientStatus": {
		"clientBlockedMessage": "Su cuenta est\u00e1 bloqueada",
		"clientBlockedLink": "Mas información...",
		"clientPendingMessage": "Estamos procesando la validaci\u00f3n de su cuenta"
	},
	"navigationMenu": {
		"accountLink": "Cuentas",
		"transferLink": "Transferencias",
		"payBillLink": "Servicios",
		"cardsLink": "Tarjetas",
		"savingsLink": "Ahorros",
		"loansLink": "Pr\u00e9stamos",
		"contactLink": "Ayuda y apoyo",
		"settingsLink": "Mira mi perfil",
		"disconnectButton": "Cerrar sesi\u00f3n"
	},
	"transfertsSubmenu": {
		"allTransfer": "Hacer una transferencia",
		"externalAccount": "Cuenta externa",
		"recipients": "Beneficiarios",
		"cashTransfer": "Transferencias pendientes",
		"recharge": "Recargar mi cuenta",
		"transactionCode": "C\u00f3digo de transacci\u00f3n",
		"simpleTransfer": "Con un n\u00famero de tel\u00e9fono"
	},
	"savingsSubmenu": {
		"mySavingsAccount": "Mis cuentas de ahorro",
		"deposits": "Transferencias y depósitos"
	},
	"simpleTransfer": {
		"newTransfer": "Nueva transferencia",
		"title": "Con un n\u00famero de tel\u00e9fono",
		"searchRecipient": "Encuentra un destinatario",
		"invalidPhoneNumber": "El número de teléfono proporcionado no es reconocido."
	},
	"accountPendingVerification": {
		"title": "Verificacion de la cuenta",
		"subtitle": "Su solicitud de apertura de cuenta esta bajo verificacion. Vuelva a intentarlo mas tarde una vez que el back-office lo haya revisado",
		"retryButton": "Reintentar"
	},
	"accountsList": {
		"title": "Mis cuentas.",
		"loadAccountsError": "Un error ocurri\u00f3 al momento de cargar de las cuentas",
		"loadAccountsRetry": "Reintentar",
		"showAllAccountsLink": "Ver todas mis cuentas",
		"noAccountAvailable": "No hay cuenta disponible"
	},
	"recipient": {
		"title": "Hacer una transferencia",
		"subtitle": "¿A quién quieres transferir tu dinero?",
		"section": "Mis beneficiarios",
		"noRecipientsAvailable": "No hay destinatarios disponible",
		"noRecipientsMessage": "Agrega destinatarios para transferir dinero.",
		"addRecipientButton": "A\u00f1adir un beneficiario",
		"transferTo": "Transferencia a {name}",
		"searchInputPlaceholder": "B\u00fasqueda",
		"selector": {
			"beneficiaries": "Beneficiarios",
			"externalAccounts": "Cuenta externa"
		},
		"deletePrompt": {
			"title": "Eliminar",
			"message": "\u00bfRRealmente desea eliminar el destinatario {name}?",
			"recipient": "el beneficiario",
			"externalAccountTitle": "Eliminar la cuenta externa",
			"externalAccountMessage": "\u00bfRealmente quiere eliminar su cuenta externa?",
			"cancelButton": "Cancelar",
			"confirmButton": "Confirmar"
		},
		"externalAccounts": {
			"title": "Cuentas externas",
			"addButton": "A\u00f1adir una cuenta externa",
			"noneAvailableTitle": "Actualmente sin cuenta externa",
			"noneAvailableMessage": "Añade una cuenta bancaria externa para transferir dinero.",
			"pendingExternalAccountMessage": "Su cuenta externa está siendo validada."
		}
	},
	"addRecipient": {
		"selector": {
			"iban": "IBAN",
			"phone": "Tel\u00e9fono",
			"account": "Núm. de cuenta / BIC"
		},
		"unauthorized": "Ud. no se encuentra autorizado a acceder a esta funcionalidad",
		"title": "A\u00f1adir",
		"subtitle": "un beneficiario",
		"externalAccountSubtitle": "una cuenta externa",
		"nameFieldPlaceholder": "Nombre",
		"phoneFieldPlaceholder": "N\u00famero de tel\u00e9fono",
		"ibanFieldPlaceholder": "IBAN",
		"accountFieldPlaceholder": "Número de cuenta",
		"bicFieldPlaceholder": "BIC",
		"bankFieldPlaceholder": "Banco/BIC",
		"createButton": "A\u00f1adir",
		"otpAdditionalText": "A\u00f1adir beneficiario {name}",
		"otpAdditionalTextWithBank": "A\u00f1adir beneficiario {name} del banco {bankName}",
		"addConfirmationMessage": "El beneficiario {name} ha sido a\u00f1adido con \u00e9xito",
		"searchBank": {
			"title": "Banco/BIC",
			"bankPlaceholder": "Escriba al menos {num} letras para buscar",
			"firstSearchMessage": "Haga una búsqueda primero para obtener resultados",
			"noBankMessage": "No se encontró banco corresponsal",
			"errorMessage": "Un error ocurrió al cargar la lista de bancos.",
			"retryButton": "Reintentar"
		}
	},
	"editRecipient": {
		"title": "Editar",
		"subtitle": "un destinatario",
		"externalAccountSubtitle": "el cuenta externa",
		"saveButton": "Guardar"
	},
	"account": {
		"accountId": "N°{id}",
		"accountType": {
			"current": "Cuenta corriente",
			"savings": "Cuenta de ahorros",
			"dedicated": "Cuenta dedicada",
			"moneyPot": "Collecta",
			"technical": "Cuenta t\u00e1cnica"
		},
		"detail": "Detalle"
	},
	"accountStatus": {
		"opened": "Abierta",
		"closed": "Cerrada",
		"blocked": "Bloqueada",
		"debitBlocked": "D\u00e9bito prohibido",
		"creditBlocked": "Cr\u00e9dito prohibido"
	},
	"accountTransactions": {
		"title": "Mis operaciones.",
		"emptyTransactionList": "No hay transacciones por el momento",
		"loadTransactionsError": "Un error",
		"loadMoreTransactionsError": "Un error ocurri\u00f3 al momento de cargar las operaciones",
		"loadMoreTransactionsRetry": "Un error ocurri\u00f3 al momento de cargar las operaciones",
		"unjustifiedTransactions": {
			"title": {
				"one": "Tienes {count} transacción en espera de justificativo",
				"other": "Tienes {count} transacciones en espera de justificativo",
				"more": "Tienes {count} or mas transacciones en espera de justificativo"
			},
			"description": "Algunas de sus transacciones están esperando documentos de verificación."
		},
		"unjustifiedTransactions2": {
			"title": {
				"one": "Tienes {count} transacción en espera de justificativo",
				"other": "Tienes {count} transacciones en espera de justificativo",
				"more": "Tienes {count} or mas transacciones en espera de justificativo"
			},
			"description": "Algunas de sus transacciones están esperando documentos de verificación."
		},
		"unqualifiedTransactions": {
			"title": {
				"one": "Tienes {count} transacción en espera de cacalificación",
				"other": "Tienes {count} transacciones en espera de calificación",
				"more": "Tienes {count} or mas transacciones en espera de calificación"
			},
			"description": "Algunas de sus transacciones están esperando documentos de calificación."
		},
		"authorizationHolds": {
			"title": {
				"one": "Tienes {count} transacción pendiente",
				"other": "Tienes {count} transacciones pendientes",
				"more": "Tienes {count} transacciones pendientes"
			},
			"description": "El dinero ha sido debitado de la cuenta pero aún no ha sido recibido por el beneficiario."
		},
		"previousPeriodButtonLabel": "Periodo anterior",
		"nextPeriodButtonLabel": "Siguiente periodo"
	},
	"authorizationHolds": {
		"cashoutLabel": "Retiro",
		"paymentLabel": "Pago",
		"externalToAccountLabel": "De sistema externo a cuenta",
		"accountToExternalLabel": "De cuenta a sistema externo",
		"externalFromAccountLabel": "Sistema externo desde cuenta",
		"accountRechargeLabel": "Recarga de cuenta",
		"onUsCreditTransferLabel": "Transferencia on-us",
		"managementFeesLabel": "Costes de administración"
	},
	"accountingTransactionDetailsModal": {
		"detailsTitle": "Detalles",
		"transferLabel": "Transferencia",
		"description": "Descripci\u00f3n: {description}",
		"transaction": "N\u00famero: {id}",
		"reference": "Référence: {id}",
		"feeLabel": "Honorario",
		"commissionLabel": "Comisi\u00f3n",
		"taxLabel": "Impuesto",
		"discountLabel": "Descuento",
		"interestLabel": "Intereses",
		"amountLabel": "Total",
		"balanceLabel": "Saldo despu\u00e9s de la operaci\u00f3n",
		"additionalInformationTitle": "Informaci\u00f3n adicional",
		"geolocationTitle": "Localizaci\u00f3n",
		"externalIdLabel": "Referencia de operaci\u00f3n del tercero: {externalid}",
		"justifyButtonLabel": "Justificar",
		"refundButtonLabel": "Solicitar un reembolso",
		"verifyJustificationsButtonLabel": "Verificar justificativos",
		"justificationFollowUpTitle": "Seguimiento de justificación",
		"documentsSubmitted": "Recibos presentados",
		"validationInProgress": "Esperando la validación"
	},
	"authorizationHoldsDetailsModal": {
		"pending": "Pendiente",
		"detailsTitle": "Detalles",
		"transferLabel": "Transferencia",
		"reference": "N\u00famero: {id}",
		"amountLabel": "Total"
	},
	"allAccountsScreen": {
		"goBackClassicViewButton": "Volver a la vista clásica",
		"noAccountAvailable": "No hay cuenta disponible"
	},
	"recipientDetail": {
		"information": "Informaci\u00f3n.",
		"accountDetail": "RIB/informaci\u00f3n de cuenta.",
		"transferMoneyButton": "Transferencia de dinero",
		"dedicatedTransferButton": "Transferencia en bono de compra"
	},
	"amountSelection": {
		"amountLabel": "Monto",
		"currencyLabel": "Divisa:",
		"descriptionLabel": "Descripci\u00f3n:",
		"descriptionButton": "A\u00f1adir una descripci\u00f3n",
		"descriptionPlaceholder": "A\u00f1adir un mensaje",
		"simpleTransferNameLabel": "Nombre :",
		"simpleTransferNamePlaceholder": "Escribir un nombre",
		"simpleTransferPhoneLabel": "Teléfono :",
		"simpleTransferPhonePlaceholder": "Escribir un teléfono",
		"sendButton": "Siguiente",
		"since": "Desde",
		"to": "Hasta",
		"choose_source_account": "Elegir una cuenta de origen",
		"choose_destination_account": "Elegir una cuenta de destino",
		"my_accounts": "Mis cuentas",
		"my_external_account": "Cuentas externa",
		"my_recipients": "Mis beneficiarios",
		"network": "Red",
		"load_more": "Cargar más",
		"convertedAmountLabel": "Monto convertido",
		"creditorAddress": {
			"addressLabel": "Direcci\u00f3n",
			"countryLabel": "País",
			"buildingNumber": "N°",
			"streetName": "Calle",
			"postCode": "Código postal",
			"townName": "Ciudad",
			"country": "País"
		}
	},
	"acceptorSelection": {
		"title": "Selecci\u00f3n de un comerciante",
		"selectionButton": "Seleccionar"
	},
	"dedicatedTransferAmount": {
		"title": "Monto del regalo",
		"subtitle": "Punto de transacci\u00f3n: {code}"
	},
	"dedicatedTransferSummary": {
		"recepient": "Destinatario",
		"account": "Cuenta debitada",
		"amount": "Monto m\u00e1ximo",
		"transactionPoint": "Punto de transacci\u00f3n",
		"expirationDate": "Fecha de expiraci\u00f3n",
		"done": "Terminar"
	},
	"pincodeConfirmation": {
		"label": "Ingrese su c\u00f3digo secreto para continuar",
		"networkError": "Verifique su conexi\u00f3n a Internet antes de continuar",
		"loadKeyboardError": "Un error ocurri\u00f3 al momento de cargar el teclado",
		"loadKeyboardRetry": "Recargar teclado",
		"forgetPincode": "Olvid\u00e9 mi c\u00f3digo secreto"
	},
	"transferSummary": {
		"accountTransferMode": "Hacia cuenta",
		"cashTransferMode": "Hacia efectivo",
		"summaryTitle": "\u00c9xito",
		"accountSummarySubtitle": "Detalle de la transferencia",
		"cashSummarySubtitle": "Ud. va a recibir un mensaje que contiene un c\u00f3digo a entregar a {recipient} o consulte sus transferencias pendientes para recuperar el c\u00f3digo.",
		"summaryMiddleStep": "Transferencia",
		"fees": "Honorarios: {amount}",
		"commission": "Comisi\u00f3n: {amount}",
		"discount": "Descuento: {amount}",
		"tax": "Impuesto: {amount}",
		"you": "Usted",
		"done": "Terminar",
		"customerInstructionSuccessMessage": "Su transferencia N°{id} a {recipient} ha sido realizada con éxito."
	},
	"billFormScreen": {
		"nextButton": "Siguiente",
		"textInputPlaceholder": "Escriba cualquier cosa...",
		"saveReferencePromptTitle": "Guardar el {name}",
		"replaceReferencePromptTitle": "Remplazar el {name}",
		"saveReferencePromptMessage": "\u00bfDesea guardar el {name} para este emisor de facturas?",
		"replaceReferencePromptMessage": "\u00bfDesea remplazar el {name} para este emisor de facturas?",
		"saveReferencePromptCancel": "Cancelar",
		"saveReferencePromptConfirm": "Guardar",
		"replaceReferencePromptConfirm": "Remplazar",
		"saveReferenceErrorMessage": "El {name} no pudo ser guardado",
		"docInputFilled": "Un archivo",
		"docInputEmpty": "Añadir or soltar una foto aquí",
		"confirmationMessage": "Haga clic a continuación para confirmar su pago de [b]{amount} [/b] a [b]{issuer}[/b]",
		"confirmButton": "Confirmar"
	},
	"billConfirmationScreen": {
		"fees": "Costes : [b]{amount}[/b]",
		"commission": "Comisión : [b]{amount}[/b]",
		"discount": "Descuento : [b]{amount}[/b]",
		"tax": "Impuesto : [b]{amount}[/b]"
	},
	"formError": {
		"invalidIBAN": "El IBAN es inv\u00e1lido",
		"invalidFileSize": "Su archivo excede el tamaño máximo aceptado ({size})",
		"invalidFileFormat": "El formato de su archivo no es compatible. Formatos aceptados: {formats}"
	},
	"billIssuersScreen": {
		"title": "Mis servicios.",
		"references": "Mis referencias",
		"searchInputPlaceholder": "B\u00fasqueda",
		"emptyListLabel": "No hay socios disponibles",
		"loadBillIssuersError": "Un error ocurri\u00f3 al momento de cargar los socios"
	},
	"billReferencesScreen": {
		"title": "Mis referencias",
		"services": "Volver a mis servicios",
		"subtitle": "Datos guardados para sus pagos",
		"emptyRecipientList": "No tiene referencias guardadas",
		"deletePromptTitle": "Eliminar la referencia",
		"deletePromptMessage": "\u00bfRealmente quiere eliminar la referencia {name}?",
		"deletePromptCancel": "Cancelar",
		"deletePromptConfirm": "Confirmar",
		"deleteConfirmationMessage": "La referencia {name} ha sido eliminada con \u00e9xito",
		"deleteErrorMessage": "La referencia {name} no ha podido ser eliminada",
		"loadBillReferencesError": "Un error ocurri\u00f3 al momento de cargar sus referencias",
		"lastUpdateDateTime": "\u00daltima actualizaci\u00f3n: {date} a {time}"
	},
	"otp": {
		"explanation": "Ingrese el c\u00f3digo de seis d\u00edgitos que le enviamos a:",
		"sendBackCodeButton": "Reenviar el c\u00f3digo",
		"backToRecipients": "Atrás",
		"confirmOtpButton": "Confirmer",
		"goToTransferFormButton": "Ir al formulario de transferencia",
		"otpBlockedErrorMessage": "Ha realizado 3 intentos incorrectos. Inicie la operación desde el principio."
	},
	"accountDetails": {
		"availableBalanceTitle": "Saldo disponible",
		"balanceTitle": "Saldo actual",
		"balanceMessage": "El saldo actual no tiene en cuenta las operaciones pendientes",
		"accountStatus": {
			"blocked": "Esta cuenta se encuentra bloqueada. Para mayor informaci\u00f3n por favor contacte nuestro soporte",
			"debitBlocked": "Los d\u00e9bitos est\u00e1n actualmente prohibidos en esta cuenta. Para mayor informaci\u00f3n por favor contacte nuestro soporte",
			"creditBlocked": "Los creditos est\u00e1n actualmente prohibidos en esta cuenta. Para mayor informaci\u00f3n por favor contacte nuestro soporte"
		},
		"accountName": "Nombre de cuenta",
		"accountIdTitle": "N\u00famero de cuenta",
		"ibanTitle": "IBAN",
		"bicTitle": "BIC",
		"accessStatements": "Estados de cuenta",
		"shareRIBLabel": "Compartir informaci\u00f3n de cuenta",
		"shareRIBMessage": "{firstName} {lastName} {postName}",
		"dedicatedMessage": "Puede gastar esta cantidad en:",
		"clipboard": "{name} copiado",
		"downloadBankDetails": "Datos bancarios",
		"accountStartDate": "Fecha de inicio de cuenta",
		"maximumBoundaries": "Saldo maximal",
		"initialAmountLabel": "Saldo inicial",
		"endDateLabel": "Saldo inicial",
		"durationLabel": "Duraci\u00f3n",
		"monthsLabels": {
			"DAILY": "días",
			"WEEKLY": "semanas",
			"MONTHLY": "mes",
			"QUATERLY": "trimestrial",
			"SEMESTER": "semestres",
			"ANNUALLY": "años"
		},
		"rateValueLabel": "Tarifa aplicada",
		"indexCodeLabel": "Indice",
		"rateStartDateLabel": "Fecha de inicio de tarifa",
		"accruedInterestsLabel": "Intereses adquiridos",
		"startDateLabel": "Adquirido desde",
		"forecastInterestsLabel": "Interés total proyectado",
		"initialAERLabel": "TRAAB",
		"success": "Cambio guardado"
	},
	"accountsStatements": {
		"title": "Estados de cuenta",
		"subtitle": "Todos mis estados de cuenta",
		"accountLabel": "Cuenta No. {id}",
		"accounts": "Cuentas",
		"statements": "Estados de cuenta disponibles",
		"emptyStatementList": "Ning\u00fan estado de cuenta est\u00e1 disponible",
		"cancel": "Cancelar",
		"loadStatementsError": "Un error ocurri\u00f3 al momento de cargar los estados de cuenta",
		"generateButton": "Generar"
	},
	"feesStatements": {
		"title": "Declaraciones de tarifas",
		"subtitle": "Todos mis estados de declaraciones de tarifas",
		"accountLabel": "Cuenta No. {id}",
		"accounts": "Cuentas",
		"statements": "Declaraciones de tarifas disponibles",
		"emptyStatementList": "Ning\u00fan declaracion de tarifas est\u00e1 disponible",
		"cancel": "Cancelar",
		"loadStatementsError": "Un error ocurri\u00f3 al momento de cargar las declaraciones de tarifas"
	},
	"cardsList": {
		"title": "Mis tarjetas.",
		"loadCardsError": "Un error ocurri\u00f3 al momento de cargar de las tarjetas",
		"loadCardsRetry": "Reintentar",
		"showAllCardsLink": "Ver todas mis tarjetas",
		"orderCard": "Pedir una nueva tarjeta",
		"refabricateButton": "Reemplazar la tarjeta",
		"infos": {
			"title": "Información adicional"
		},
		"details": {
			"title": "Detalles de la tarjeta",
			"holder": "Nombre del titular",
			"number": "N\u00famero de la tarjeta",
			"date": "Fecha de expiraci\u00f3n"
		}
	},
	"allCardsScreen": {
		"goBackClassicViewButton": "Volver a la vista clásica",
		"noCardAvailable": "No hay tarjeta disponible"
	},
	"card": {
		"virtual": "Virtual",
		"title": "TARJETA",
		"status": {
			"all": "Todas",
			"active": "Activa",
			"pending": "Pendiente",
			"new": "Nueva",
			"expired": "Expirada",
			"blocked": "Bloqueada",
			"opposed": "Opuesta",
			"removed": "Remota",
			"cancelled": "Cancelada"
		},
		"feature": {
			"nfc": "Sin contacto",
			"cashOperation": "Retiro",
			"foreignPayment": "Pago en el extranjero",
			"onlinePayment": "Pago en l\u00ednea",
			"eurWithdrawal": "Retiro en la zona euro",
			"foreignWithdrawal": "Retiro en el extranjero",
			"paymentAuthorized": "Pago autorizado",
			"eurPayment": "Pago en la zona euro",
			"foreignAll": "Retiro y pago en el extranjero",
			"foreignOnlinePayment": "Pago en l\u00ednea en el extranjero"
		},
		"pendingOperation": {
			"refabrication": "Se está rehaciendo la tarjeta",
			"codeReissuing": "Se está cambiando el código PIN de su tarjeta",
			"opposition": "Su tarjeta se está en curso de opuesta",
			"blocking": "Su tarjeta se está en curso de bloqueada",
			"unblocking": "Su tarjeta se está en curso de desbloqueo",
			"activation": "Su tarjeta se está en curso de activada",
			"creation": "Su tarjeta se está creando",
			"limitsUpdate": "Se están cambiando los límites de su tarjeta",
			"featuresUpdate": "Se está cambiando la funcionalidad \"{feature}\" de su tarjeta",
			"unknownFeatureUpdate": "Se está cambiando una funcionalidad de su tarjeta"
		}
	},
	"cardPincode": {
		"importantMessage": "Tenga cuidado de conservar este código:\nLo necesitará para validar transacciones con su tarjeta.",
		"previous": "Cambiar",
		"next": "Siguiente",
		"errorCodesMismatch": "Los códigos no coinciden."
	},
	"cardOptions": {
		"title": "Configuración de pago",
		"outstandings": {
			"navigateButton": "Mis límites",
			"title": "Mis límites",
			"subtitle": "Ver los límites de su tarjeta",
			"paymentHeader": "Límites de pago",
			"cashoutHeader": "Límites de retiro",
			"paymentLimits": "Límites de pago",
			"slidingPeriod": "periodo de {num} {unit} consecutivos",
			"fixedPeriod": "del {startDate} al {endDate}",
			"used": "Usó",
			"available": "Disponible",
			"maxAmount": "Monto máx",
			"maxOperations": "Operaciones máx",
			"selector": {
				"payments": "Pagos",
				"cashouts": "Retiros"
			},
			"noOutstandingLabel": "No límites",
			"errorMessage": "Un error ocurrió al cargar los límites.",
			"retryButton": "Reintentar",
			"durationUnit": {
				"hour": "hora",
				"hours": "horas",
				"day": "día",
				"days": "días",
				"week": "semana",
				"weeks": "semanas",
				"month": "mes",
				"months": "meses",
				"year": "año",
				"years": "años"
			},
			"updateButtonLabel": "Modificar su límite",
			"modal": {
				"title": "Modificar su límite",
				"inputLabel": "Fijar un límite",
				"inputLabelUnit": {
					"day": "diario",
					"week": "semanal",
					"month": "mensual",
					"year": "anual",
					"all": "global"
				},
				"maximumLabel": "Maximo",
				"submitButtonLabel": "Modificar",
				"validationMsg": "Su modificación ha sido tomada en cuenta"
			}
		},
		"refabricate": {
			"modal": {
				"title": "Confirmación",
				"message": "Confirma que deseas reemplazar esta tarjeta",
				"cardNumber": "{cardNumber} ",
				"submitButtonLabel": "Confirmar"
			},
			"motiveLabel": "Seleccione el motivo",
			"motivesOptions": {
				"DAMAGED": "Tarjeta danada",
				"STOLEN": "Tarjeta robada",
				"LOST": "Tarjeta perdida",
				"ABUSE": "Uso fraudulento"
			}
		},
		"oppositionCard": {
			"opposeButton": "Hacer oposici\u00f3n",
			"title": "Para hacer oposici\u00f3n y bloquear permanentemente su tarjeta, elija una de las siguientes razones:",
			"reasonLost": "Tarjeta perdida",
			"reasonStolen": "Tarjeta robada",
			"reasonNotDistributed": "Tarjeta no distribuida",
			"opposeConfirmButton": "Confirmar",
			"opposeCancelButton": "Cancelar",
			"opposePromptTitle": "Hacer oposici\u00f3n",
			"opposePromptMessage": "Esta operaci\u00f3n es irreversible. \u00bfEst\u00e1 seguro de querer hacer oposici\u00f3n?"
		},
		"deactivateCard": {
			"deactivateButton": "Hacer deactivac\u00f3n",
			"title": "Para hacer deactivac\u00f3n y bloquear permanentemente su tarjeta, elija una de las siguientes razones:",
			"deactivateConfirmButton": "Confirmar",
			"deactivateCancelButton": "Cancelar",
			"deactivatePromptTitle": "Hacer deactivac\u00f3n",
			"deactivatePromptMessage": "Esta operaci\u00f3n es irreversible. \u00bfEst\u00e1 seguro de querer hacer oposici\u00f3n?"
		},
		"updateCardPincode": {
			"updateButton": "Cambiar PIN de tarjeta",
			"title": "Edición de código",
			"definePincodeMessage": "Elija un nuevo PIN de cuatro d\u00edgitos para su tarjeta. Sobre todo, no se lo comunique a nadie, es estrictamente confidencial.",
			"confirmPincodeMessage": "Confirme el código PIN de su tarjeta para continuar.",
			"successTitle": "\u00a1Felicidades!",
			"successSubtitle": "¡El código PIN ha sido actualizado!",
			"failureTitle": "Error",
			"failureSubtitle": "Pedimos disculpas.",
			"failureDescription": "Parece que hubo un error al cmbiar el código PIN por su tarjeta. Int\u00e9ntelo de nuevo m\u00e1s tarde o p\u00f3ngase en contacto con nuestro servicio de atenci\u00f3n al cliente.",
			"confirmationPopup": {
				"message": "Si deja de editar el código PIN, no se actualizará.\n¿Dejar de editar?"
			}
		},
		"activationCard": {
			"title": "Activar mi tarjeta",
			"activateButton": "Activar",
			"defaultActivationMessage": "Ingrese el identificador de 9 d\u00edgitos en el reverso de su tarjeta.",
			"identifier": "Su identificador de 9 d\u00edgitos",
			"next": "Siguiente",
			"confirm": "Activar",
			"inputError": "Error de entrada, inténtalo de nuevo.",
			"stepPincode": {
				"title": "Código PIN",
				"definePincodeMessage": "Ahora debe elegir un código PIN de cuatro d\u00edgitos para su tarjeta. Sobre todo, no se lo comunique a nadie, es estrictamente confidencial.",
				"confirmPincodeMessage": "Confirme el código PIN de su tarjeta para continuar."
			}
		},
		"switch": {
			"lockCard": "Bloqueo temporal"
		},
		"featuresSwitchesOverlay": {
			"cardLocked": "Tarjeta bloqueada"
		},
		"cardOpposedMessage": "Ud. ha realizado una oposici\u00f3n sobre esta tarjeta y por eso no puede acceder a sus opciones"
	},
	"accountBlockedScreen": {
		"title": "Cuenta bloqueada",
		"multipleAttemptBlockedReason": {
			"subtitle": "Tres intentos err\u00f3neos de c\u00f3digo secreto",
			"message": "Lamentablemente, su cuenta ha sido bloqueada debido a tres intentos incorrectos de c\u00f3digo secreto. P\u00f3ngase en contacto con nuestro soporte {contacts}para restablecer su c\u00f3digo secreto."
		},
		"defaultBlockedReason": {
			"message": "Lamentablemente, su cuenta ha sido bloqueada. P\u00f3ngase en contacto con nuestro soporte {contacts}para restablecer su c\u00f3digo secreto."
		},
		"contactsPhoneAndEmail": "al {contactPhone} o por correo electr\u00f3nico a {contactEmail} ",
		"contactsPhone": "al {contactPhone} ",
		"contactsEmail": "o por correo electr\u00f3nico a {contactEmail} ",
		"button": "OK entend\u00ed"
	},
	"bankContactScreen": {
		"title": "Mi banco.",
		"question": "¿Necesitas ayuda?",
		"addressContact": "Encu\u00e9ntranos",
		"phoneContact": "Ll\u00e1manos",
		"urlContact": "Qui\u00e9nes somos",
		"emailContact": "Escr\u00edbenos"
	},
	"pincodeChangeFlow": {
		"currentCodeLabel": "Ingrese su c\u00f3digo actual",
		"newCodeLabel": "Ingrese su nuevo c\u00f3digo",
		"newCodeConfirmationLabel": "Confirme su nuevo c\u00f3digo",
		"newCodesDontMatchError": "Los c\u00f3digos ingresados no corresponden",
		"success": "Su c\u00f3digo ha sido cambiado con \u00e9xito"
	},
	"pincodeChangeScreen": {
		"title": "Cambio de c\u00f3digo",
		"subtitle": "Cambie su c\u00f3digo secreto para mayor seguridad",
		"message": "Es necesario que Ud. cambie su c\u00f3digo secreto antes de realizar esta operaci\u00f3n",
		"messageAfterAuthentication": "Ud. debe cambiar su c\u00f3digo secreto antes de realizar nuevas operaciones",
		"changePincodeButton": "Cambiar mi c\u00f3digo secreto",
		"laterButton": "M\u00e1s tarde",
		"done": "Terminar"
	},
	"clientInformation": {
		"title": "Mi informacion",
		"birthDateTitle": "Fecha de nacimiento",
		"birthPlaceTitle": "Lugar de nacimiento",
		"addressTitle": "Direcci\u00f3n",
		"phoneTitle": "Tel\u00e9fono",
		"emailTitle": "Email",
		"emailSuccess": "Usted ha recibido un correo electrónico de confirmación"
	},
	"cashTransferScreen": {
		"title": "Mis transferencias pendendientes",
		"subtitle": "Cancelar o recuperar su dinero",
		"receivedSelector": "Recibidas",
		"sentSelector": "Enviadas",
		"recipient": "Enviado a : ",
		"sender": "Envoyé por : ",
		"emptyReceivedList": "Ninguna transferencia recibida",
		"emptySentList": "Ninguna transferencia enviada",
		"receivedTransferDefaultTitle": "Transferencia recibida",
		"sentTransferDefaultTitle": "Transferencia enviada",
		"codeLabel": "C\u00f3digo: {code}",
		"cancelCashTransferButton": "Cancelar la transferencia",
		"retrieveCashTransferButton": "Enviar a mi cuenta",
		"loadCashTransfersError": "Un error ocurri\u00f3 al momento de cargar las transferencias en efectivo",
		"loadMoreCashTransfersError": "Un error ocurri\u00f3 al momento de cargar las transferencias en efectivo",
		"loadMoreCashTransfersRetry": "Intentar de nuevo",
		"nextButton": "Siguiente",
		"cashCodeTitle": "Ingrese el c\u00f3digo enviado por el remitente de la transferencia",
		"invalidCode": "El c\u00f3digo ingresado no es v\u00e1lido",
		"summaryTitle": {
			"sentCashTransfer": "Transferencia cancelada",
			"receivedCashTransfer": "Transferencia recuperada en su cuenta"
		}
	},
	"settings": {
		"title": "Configuraciones",
		"changeLanguageTitle": "Idioma",
		"changeLanguageDescription": "Elija el idioma de la aplicaci\u00f3n y de los mensajes que le enviaremos",
		"languageChangeConfirmation": "El idioma ha sido cambiado con \u00e9xito",
		"languageChangeFailed": "Un error se produjo al momento de cambiar el idioma",
		"changePincodeTitle": "Cambiar mi c\u00f3digo secreto",
		"changePincodeDescription": "Para mayor seguridad, cambie su c\u00f3digo secreto regularmente",
		"accountDeleteTitle": "Eliminar mi cuenta",
		"accountDeleteDescription": "Eliminar mi cuenta de Skaleet de forma permanente."
	},
	"accountDeleteScreen": {
		"title": "¡Te extrañaremos!",
		"subtitle": "Tenga en cuenta que no necesita eliminar su cuenta si:",
		"button": "Eliminar su cuenta",
		"explanation": "Su solicitud será enviada a nuestro equipo. Esta operación puede tardar varios días.",
		"options": {
			"changeProfile": {
				"title": "Quieres cambiar su c\u00f3digo",
				"description": "Cambias su c\u00f3digo secreto."
			},
			"unHappy": {
				"title": "No estás contento",
				"description": "Contáctanos y cuéntanos por qué."
			}
		},
		"confirm": {
			"title": "Realmente deseas solicitar el cierre de tu cuenta?",
			"subtitle": "Perderas permanentemente todos los datos de tu cuenta, contactos e informacion de perfil",
			"placeholder": "Proporcione una razon para cerrar la cuenta",
			"warning": "Despues de esto ya no hay vuelta atras",
			"button": "Confirmar",
			"success": "La solicitud de cierre de cuenta se ha enviado correctamente"
		}
	},
	"accountDeleteMail": {
		"subject": "Solicitud para eliminar la cuenta de {username}",
		"body": "Hola, les pido amablemente que eliminen mi cuenta de usuario."
	},
	"transactionFilter": {
		"all": "Todos",
		"credit": "Ingresos",
		"debit": "Gastos",
		"searchDescription": "Usando el motor de b\u00fasqueda, encuentre f\u00e1cilmente sus gastos.",
		"searchInputPlaceholder": "Buscar",
		"timeRangeSelectionTitle": "Fecha personalizada",
		"timeRange": {
			"month": "Este mes",
			"week": "\u00faltimos 7 d\u00edas",
			"quarter": "\u00faltimos 3 meses",
			"from": "Del :",
			"to": "Al :"
		},
		"advancedSearch": "B\u00fasqueda avanzada",
		"confirm": "Confirmar",
		"amountRangeSelectionTitle": "Filtrar por cantidad",
		"allAmountRange": "Todas las cantidades",
		"amountRange": {
			"min": "M\u00edn:",
			"max": "M\u00e1x:"
		},
		"emptySearchResult": "No hay transacciones"
	},
	"resultScreen": {
		"activateCard": {
			"successTitle": "Felicidades !",
			"successSubtitle": "Tu tarjeta ha sido activada !",
			"failureTitle": "Error",
			"failureSubtitle": "Le pedimos disculpas.",
			"failureDescription": "Parece que se ha producido un error al activar su tarjeta. Vuelva a intentarlo más tarde o póngase en contacto con nuestro servicio de atención al cliente."
		},
		"createCard": {
			"successTitle": "\u00a1Felicidades!",
			"defaultSuccessSubtitle": "Su tarjeta ha sido creada !",
			"failureTitle": "Error",
			"failureSubtitle": "Pedimos disculpas.",
			"failureDescription": "Parece que hubo un error al crear su tarjeta. Int\u00e9ntelo de nuevo m\u00e1s tarde o p\u00f3ngase en contacto con nuestro servicio de atenci\u00f3n al cliente."
		},
		"updateOutstanding": {
			"successTitle": "\u00a1Felicidades!",
			"defaultSuccessSubtitle": "Tu límite ha sido cambiado.",
			"failureTitle": "Error",
			"failureSubtitle": "Le pedimos disculpas.",
			"failureDescription": "Parece que hubo un error al cambiar su límite. Int\u00e9ntelo de nuevo m\u00e1s tarde o p\u00f3ngase en contacto con nuestro servicio de atenci\u00f3n al cliente."
		},
		"refabricate": {
			"successTitle": "\u00a1Felicidades!",
			"successSubtitle": "Su demanda ha sido tomada en cuenta",
			"failureTitle": "Error",
			"failureSubtitle": "Pedimos disculpas.",
			"failureDescription": "Parece que hubo un error al reemplazar su tarjeta. Int\u00e9ntelo de nuevo m\u00e1s tarde o p\u00f3ngase en contacto con nuestro servicio de atenci\u00f3n al cliente."
		},
		"sensitiveDetails": {
			"successTitle": "\u00a1Felicidades!",
			"successSubtitle": "Continuar consultando los datos sensibles de la tarjeta",
			"failureTitle": "Error",
			"failureSubtitle": "Pedimos disculpas.",
			"failureDescription": "Parece que hubo un error al obtener los datos confidenciales de su tarjeta. Int\u00e9ntelo de nuevo m\u00e1s tarde o p\u00f3ngase en contacto con nuestro servicio de atenci\u00f3n al cliente."
		},
		"done": "Terminado"
	},
	"createCard": {
		"title": "Nueva tarjeta",
		"virtualCardtitle": "Nueva tarjeta virtual",
		"loadProductsError": "Un error ocurri\u00f3",
		"loadProductsRetry": "Reintentar",
		"stepSelection": {
			"title": "Selección de cuenta",
			"selectAccountMessage": "Seleccione la cuenta para la que desea solicitar una tarjeta:",
			"selectVirtualCardMessage": "Seleccione la cuenta para la que desea solicitar una tarjeta virtual:",
			"selecProductMessage": "¿ Qué tarjeta te gustaría pedir ?",
			"selectProductForVirtualCardMessage": "¿ Qué tarjeta virtual te gustaría pedir ?",
			"orderCardButton": "Pedir mi tarjeta",
			"createVirtualCardButton": "Crear una tarjeta virtual",
			"virtualCardTitle": "Obtenga una tarjeta virtual gratuita",
			"virtualCardDescription": "Para compras en línea seguras sin temor a perder su tarjeta !",
			"accountLabel": "Cuenta No. {id}"
		},
		"stepCgu": {
			"title": "Términos de servicio",
			"acceptMessage": "Al activar el interruptor, confirmas que has leído y aceptado nuestras condiciones generales de uso.",
			"next": "Siguiente"
		}
	},
	"recharge": {
		"title": "Elige una cuenta",
		"description": "Seleccione la cuenta para recargar",
		"noAccountAvailable": "No cuenta recargable",
		"noAccountExplanation": "Parece que no tienes una cuenta recargable.",
		"contactSupport": "Soporte de contacto",
		"rechargeButton": "Recargar"
	},
	"rechargeMethod": {
		"title": "Modos de carga",
		"subtitle": "¿C\u00f3mo quieres recargar tu cuenta?",
		"withCard": {
			"title": "Tarjeta de cr\u00e9dito",
			"description": "Su cuenta se acreditar\u00e1 instant\u00e1neamente. Retiros en bancos asociados en todo el mundo.",
			"instantTag": "Instant\u00e1neo"
		},
		"bankDebit": {
			"title": "D\u00e9bito",
			"description": "Financie la cuenta desde su cuenta externa {bankName}. Su cuenta no se acreditar\u00e1 instant\u00e1neamente.",
			"noBankDescription": "Financie la cuenta desde su cuenta externa. Su cuenta no se acreditar\u00e1 instant\u00e1neamente."
		}
	},
	"contactSupport": {
		"rechargeNotAvailableTitle": "¿ No puedes recargar tu cuenta?",
		"rechargeNotAvailableMessage": "Comun\u00edquese con nuestro equipo de soporte que le dir\u00e1 qu\u00e9 hacer..",
		"contactByPhone": "Cont\u00e1ctanos por tel\u00e9fono al {phoneNumber}",
		"contactByEmail": "Cont\u00e1ctenos por correo electr\u00f3nico al {email}",
		"done": "Terminar"
	},
	"rechargeBankToWallet": {
		"title": "Recarga por d\u00e9bito bancaria",
		"subtitle": "Cantidad a recargar",
		"accountWithdrawnTitle": "Cuenta externa de debito",
		"accountWithdrawnMessage": "Editable a través del menú Transferencias",
		"iban": "IBAN",
		"amountToWithdraw": "Monto a retirar:",
		"recharge": "Recargar"
	},
	"rechargeByCard": {
		"title": "Recarga con tarjeta",
		"subtitle": "Cantidad a recargar",
		"creditedAccountTitle": "Cuenta acreditada",
		"accountId": "N°{id}",
		"amountToCredit": "Monto a acreditar :",
		"recharge": "Recargar"
	},
	"rechargeSummary": {
		"title": "\u00c9xito",
		"rechargedAccount": "Cuenta recargada",
		"rechargeMethod": "Modo de recarga",
		"bankToWalletMethod": "D\u00e9bito",
		"cardMethod": "Por tarjeta",
		"rechargeAmount": "Cantidad recargada",
		"done": "Terminar"
	},
	"transactionCode": {
		"title": "C\u00f3digo de transacci\u00f3n",
		"description": "Crea un c\u00f3digo para usarlo en uno de nuestros puntos de transacci\u00f3n.",
		"noCodeAvailable": "Sin c\u00f3digo de transacci\u00f3n activo",
		"noCodeAvailableExplanation": "Para generar un nuevo c\u00f3digo de transacci\u00f3n, obtenga el n\u00famero de 6 d\u00edgitos del socio.",
		"codeTooltip": "C\u00f3digo",
		"newCodeButton": "Nuevo c\u00f3digo",
		"modalBackButton": "Volver",
		"selectAcceptor": {
			"title": "Punto de transacci\u00f3n",
			"description": "Ingrese el n\u00famero de 6 d\u00edgitos del punto de transacci\u00f3n",
			"next": "Siguiente"
		},
		"selectAmount": {
			"title": "Cantidad permitida",
			"acceptorReminder": "Punto de transacci\u00f3n : {acceptorId}",
			"currencyLabel": "Divisa :",
			"amountLabel": "Monto :",
			"amountInformationLabel": "Monto m\u00e1ximo de transacci\u00f3n (incluidas las tarifas)",
			"next": "Genera mi c\u00f3digo"
		},
		"pincode": {
			"title": "C\u00f3digo secreto",
			"description": "Ingresa tu c\u00f3digo secreto para terminar"
		},
		"summary": {
			"title": "\u00c9xito",
			"transactionCode": "C\u00f3digo de transacci\u00f3n",
			"transactionPoint": "Punto de transacci\u00f3n",
			"maxAmount": "Monto m\u00e1ximo",
			"expiryDate": "Fecha de caducidad",
			"done": "Terminar"
		}
	},
	"trustChoice": {
		"title": "¿Confías en este navegador?",
		"explanation": "Si elige confiar en este navegador, no tendrá que ingresar un código de validación durante su próxima conexione.",
		"laterButton": "Otra vez",
		"dontTrustButton": "Confiar",
		"trustButton": "No confíes"
	},
	"ubbleCheckScreen": {
		"success": {
			"title": "¡BIEN HECHO!",
			"subtitle": "¡Gracias por su registro!",
			"message": "Su identidad  está siendo verificada por nosotros. El proceso de verificación puede tardar de unas horas a unos días.\nAhora puede conectarse a su espacio o cerrar esta ventana.",
			"button": "Continuar"
		},
		"fail": {
			"title": "LO SIENTO",
			"subtitle": "Ocurrió un error durante su registro",
			"message": "Puedes volver a intentarlo usando el mismo enlace que antes.\nSi experimenta otro error, comuníquese con nuestro equipo por teléfono o correo electrónico utilizando los botones a continuación.",
			"byPhone": "Tel\u00e9fono",
			"byEmail": "Correo electr\u00f3nico"
		}
	},
	"justifyScreen": {
		"title": "Justificar mi gasto",
		"subtitle": "Agregar y editar una credencial",
		"done": "Terminar",
		"attachments": {
			"title": "Documentos de respaldo",
			"sectionTitle": "Subir o enviar prueba",
			"sectionSubtitle": "En formato pdf, jpeg, png",
			"addButtonLabel": "Agregar un recibo",
			"addOtherButtonLabel": "Agregar un otro recibo",
			"deletePrompt": {
				"title": "Supprimer le justificatif",
				"message": "Êtes-vous sûr de vouloir supprimer ce justificatif ?",
				"cancelButton": "Annuler",
				"confirmButton": "Supprimer"
			}
		},
		"spendings": {
			"title": "Gastos",
			"sectionTitle": "Añadir un gasto",
			"sectionSubtitle": "Declarar los gastos menores de su recibo",
			"addButtonLabel": "Añadir un gasto",
			"addOtherButtonLabel": "Agregar otro gasto",
			"addModal": {
				"title": "Añadir un gasto",
				"editTitle": "Editar un gasto",
				"message": "Detalles adicionales del justificativo",
				"createButton": "Añadir",
				"editButton": "Editar"
			},
			"addForm": {
				"titleLabel": "Título",
				"categoryLabel": "Categoría",
				"subcategoryLabel": "Subcategoría",
				"amountLabel": "Cantidad",
				"additionalDataLabel": "Datos adicionales"
			},
			"deletePrompt": {
				"title": "Supprimer le justificatif",
				"message": "Êtes-vous sûr de vouloir supprimer ce justificatif ?",
				"cancelButton": "Annuler",
				"confirmButton": "Supprimer"
			}
		}
	},
	"refundTransactionModal": {
		"title": "Solicitar un reembolso",
		"message": "Para solicitar un reembolso, elija un motivo a continuacion:",
		"reasons": {
			"AC03": "IBAN incorrecto",
			"AM09": "Cantidad incorrecta",
			"CUST": "Decisión del cliente"
		},
		"confirmButton": "Confirmar",
		"cancelButton": "Cancelar",
		"success": "Su solicitud de reembolso ha sido enviada"
	}
}