import React, { useMemo } from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { featuresManager } from "../../../../../shared/core/service/services";
import { useClientDisplayName } from "../../../../../shared/domains/client/client";
import type { Recipient } from "../../../../../shared/domains/recipients/recipient";
import { RecipientStateEnum } from "../../../../../shared/domains/recipients/recipient";
import { useExternalAccounts } from "../../../../../shared/domains/recipients/use-external-accounts";
import { useObservable } from "../../../../../shared/utils/observable";
import { caseInsensitiveIncludes } from "../../../../../shared/utils/string";
import { ADD_RECIPIENT_MODAL_ID, EDIT_RECIPIENT_MODAL_ID, TRANSFER_MODAL_ID } from "../../../../core/modal/modal-id";
import { useClient } from "../../../../domain/authentication/use-client";
import { useRTL } from "../../../../domain/language/use-rtl";
import { Modal } from "../../../common/modal/modal";
import { PageHeader } from "../../../common/nav/page-header";
import { PageSectionTitle } from "../../../common/nav/page-section-title";
import { ExternalAccountPlaceholder } from "../../../common/svg/external-account-placeholder";
import { PlusIcon } from "../../../common/svg/plus-icon";
import { theme } from "../../../styles/theme";
import { performTaskIfPossible } from "../../pincode/assert-banking-task";
import { AddBeneficiaryModal } from "../../recipient/add-recipient-modal";
import { EditBeneficiaryModal } from "../../recipient/edit-recipient-modal";
import { EmptyBlock } from "./empty-block";
import { RecipientCard } from "./recipient-card";
import { RecipientDetailModal } from "./recipient-detail";

export function ExternalAccountsView({ search }: { search?: string }) {
  const { formatMessage } = useIntl();
  const { client } = useClient();
  const displayName = useClientDisplayName(client, false);
  const { isRTL } = useRTL();
  const { data: externalAccounts, loading } = useExternalAccounts();
  const features = useObservable(featuresManager.features);

  const openAddExternalAccountModal = (name?: string, forExternalAccount?: boolean) =>
    Modal.present(
      ADD_RECIPIENT_MODAL_ID,
      () => <AddBeneficiaryModal initialName={name} forExternalAccount={forExternalAccount} />,
      {
        canBeDismissed: false,
      },
    );

  const openRecipientDetailModal = (recipient: Recipient) =>
    Modal.present(TRANSFER_MODAL_ID, () => <RecipientDetailModal recipient={recipient} />, {
      canBeDismissed: false,
    });

  const filteredExternalAccounts = useMemo(
    () =>
      search
        ? externalAccounts.filter(
            (r) => caseInsensitiveIncludes(r.name, search) || caseInsensitiveIncludes(r.phone, search),
          )
        : externalAccounts,
    [externalAccounts, search],
  );

  const openEditBeneficiaryModal = (recipient: Recipient, forExternalAccount?: boolean) =>
    Modal.present(
      EDIT_RECIPIENT_MODAL_ID,
      () => <EditBeneficiaryModal recipient={recipient} forExternalAccount={forExternalAccount} />,
      {
        canBeDismissed: false,
      },
    );

  return (
    <>
      {filteredExternalAccounts.length > 0 && features.externalAccountsManagement && (
        <AddExternalAccountContainer
          onClick={performTaskIfPossible(() => openAddExternalAccountModal(displayName, true))}
        >
          <RoundContainer>
            <PlusIcon width={18} height={18} />
          </RoundContainer>
          <AddExternalAccountLabel>{formatMessage("recipient.externalAccounts.addButton")}</AddExternalAccountLabel>
        </AddExternalAccountContainer>
      )}
      <Header>
        <Title $isRTL={isRTL}>{formatMessage("recipient.externalAccounts.title")}</Title>
      </Header>
      {externalAccounts.length > 0
        ? filteredExternalAccounts.map((externalAccount) => (
            <RecipientCard
              key={externalAccount.id}
              externalAccount={externalAccount}
              onEdit={performTaskIfPossible(() => openEditBeneficiaryModal(externalAccount, true))}
              onClick={performTaskIfPossible(() => openRecipientDetailModal(externalAccount))}
              isRTL={isRTL}
              readonly={!features.externalAccountsManagement}
              disabled={externalAccount.state === RecipientStateEnum.PENDING}
            />
          ))
        : !loading && (
            <EmptyBlock
              illustration={<ExternalAccountPlaceholder />}
              title={formatMessage("recipient.externalAccounts.noneAvailableTitle")}
              message={formatMessage("recipient.externalAccounts.noneAvailableMessage")}
              button={formatMessage("recipient.externalAccounts.addButton")}
              onClick={
                features.externalAccountsManagement
                  ? performTaskIfPossible(() => openAddExternalAccountModal(displayName, true))
                  : undefined
              }
            />
          )}
    </>
  );
}

const Title = styled(PageSectionTitle)<{ $isRTL: boolean }>`
  font-size: 0.9375rem;
  ${theme.mediumText};
  margin-left: ${(props) => (props.$isRTL ? 10 : 0)}px;
  margin-right: ${(props) => (props.$isRTL ? 0 : 10)}px;
`;

const Header = styled(PageHeader)`
  align-self: stretch;
`;

const AddExternalAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 12px;
  margin-right: 12px;
  :hover {
    cursor: pointer;
  }
`;

const AddExternalAccountLabel = styled.span`
  ${theme.text}
  font-size: 0.9375rem;
  text-align: left;
  margin-horizontal: 16px;
`;

const RoundContainer = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #ececec;
  align-items: center;
  justify-content: center;
`;
