import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import type { Account } from "../../../../../shared/domains/account/account";
import { getAvailableBalance } from "../../../../../shared/domains/account/account";
import { useAccounts } from "../../../../../shared/domains/account/use-accounts";
import { useBeneficiaries } from "../../../../../shared/domains/recipients/use-beneficiaries";
import { useExternalAccounts } from "../../../../../shared/domains/recipients/use-external-accounts";
import type { AccountOrRecipient } from "../../../../../shared/domains/transactions/customer-instruction";
import { AmountText } from "../../../common/amount-text";
import { Avatar } from "../../../common/avatar/avatar";
import { DefaultButton } from "../../../common/buttons/default-button";
import { theme } from "../../../styles/theme";

export enum SourceAndDestinationEnum {
  Source = "source",
  Destination = "destination",
}
interface SourceAndDestinationProps {
  needCreditorAddress?: boolean;
  needCreditorAccountNumber?: boolean;
  needCreditorPhoneNumber?: boolean;
  type?: SourceAndDestinationEnum | null;
  sourceAccount?: Account | null;
  onSourceAccountChange?: (account: Account) => void;
  onDestinationAccountOrRecipientChange?: (account: AccountOrRecipient) => void;
}

interface AccountButtonProps {
  account: AccountOrRecipient;
  onClick?: () => void;
  showAvatar?: boolean;
}

export const AccountButton = (props: AccountButtonProps) => {
  const { account, onClick, showAvatar = true } = props;

  return (
    <StyledButton justified={!!getAvailableBalance(account)} onClick={onClick}>
      {!!account.name && !!getAvailableBalance(account) ? (
        <>
          <div>
            <StyledButtonLeft>{account.name}</StyledButtonLeft>
            <StyledIban>{account.iban}</StyledIban>
          </div>
          {getAvailableBalance(account) ? <StyledAmountText amount={getAvailableBalance(account)} /> : null}
        </>
      ) : (
        <>
          {showAvatar && <AvatarWrapper fontSize="10px" size={24} name={account.name} />}
          <div>
            <AccountNameWrapper>{account.name}</AccountNameWrapper>
            <StyledIban>{account.iban || account.phone || account.accountReference}</StyledIban>
          </div>
        </>
      )}
    </StyledButton>
  );
};

export const SourceAndDestination = (props: SourceAndDestinationProps) => {
  const {
    needCreditorPhoneNumber,
    needCreditorAccountNumber,
    needCreditorAddress,
    sourceAccount,
    type,
    onSourceAccountChange,
    onDestinationAccountOrRecipientChange,
  } = props;
  const accounts = useAccounts();
  const { data: externalAccounts } = useExternalAccounts();
  const { data: recipients, canLoadMore, loadMore } = useBeneficiaries();
  const { formatMessage } = useIntl();

  const destinationAccounts = accounts.filter((account) => account.id !== sourceAccount?.id);

  const filteredExternalAccounts = needCreditorPhoneNumber
    ? externalAccounts?.filter((account) => !!account.phone)
    : needCreditorAccountNumber
      ? externalAccounts?.filter((account) => !!account.accountReference)
      : externalAccounts;

  const filteredRecipients = needCreditorPhoneNumber
    ? recipients.filter((recipient) => !!recipient.phone)
    : needCreditorAccountNumber
      ? recipients.filter((recipient) => !!recipient.accountReference)
      : recipients;

  return type === SourceAndDestinationEnum.Source ? (
    <>
      <StyledLabel>{formatMessage("amountSelection.my_accounts")}</StyledLabel>
      {accounts.map((account, index) => (
        <AccountButtonWrapper key={index}>
          <AccountButton account={account as AccountOrRecipient} onClick={() => onSourceAccountChange?.(account)} />
        </AccountButtonWrapper>
      ))}
    </>
  ) : type === SourceAndDestinationEnum.Destination ? (
    <>
      {destinationAccounts.length > 0 ? (
        <>
          <StyledLabel>{formatMessage("amountSelection.my_accounts")}</StyledLabel>
          {destinationAccounts.map((account, index) => (
            <AccountButtonWrapper key={index}>
              <AccountButton
                account={account as AccountOrRecipient}
                onClick={() => onDestinationAccountOrRecipientChange?.(account as AccountOrRecipient)}
              />
            </AccountButtonWrapper>
          ))}
        </>
      ) : null}
      {filteredExternalAccounts?.length > 0 ? (
        <>
          <StyledLabel>{formatMessage("amountSelection.my_external_account")}</StyledLabel>
          {filteredExternalAccounts.map((account, index) => (
            <AccountButtonWrapper key={index}>
              <AccountButton
                account={account as AccountOrRecipient}
                onClick={() => onDestinationAccountOrRecipientChange?.(account as AccountOrRecipient)}
                showAvatar={false}
              />
            </AccountButtonWrapper>
          ))}
        </>
      ) : null}
      {filteredRecipients?.length > 0 ? (
        <>
          <StyledLabel>{formatMessage("amountSelection.my_recipients")}</StyledLabel>
          {filteredRecipients.map((account, index) => (
            <AccountButtonWrapper key={index}>
              <AccountButton
                account={account as AccountOrRecipient}
                onClick={() => onDestinationAccountOrRecipientChange?.(account as AccountOrRecipient)}
              />
            </AccountButtonWrapper>
          ))}
          {canLoadMore ? (
            <DefaultButton onClick={loadMore}>{formatMessage("amountSelection.load_more")}</DefaultButton>
          ) : null}
        </>
      ) : null}
    </>
  ) : null;
};

const StyledButton = styled("div")<{ justified: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  box-shadow: none;
  background-color: white;
  text-align: left;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  min-width: 0;
  ${theme.bodyBlackRegular};
  ${(props) => (props.justified ? "justify-content: space-between;" : "")}
`;

const AccountButtonWrapper = styled.div`
  margin-bottom: 15px;
`;

const StyledButtonLeft = styled.span`
  white-space: wrap;
  margin-right: 10px;
`;

const StyledAmountText = styled(AmountText)`
  ${theme.boldText};
`;

const StyledLabel = styled.p`
  margin: 30px 0 5px;
  ${theme.mediumText};
`;

const StyledIban = styled.span`
  display: block;
  ${theme.bodyGreyRegular};
  font-size: 12px;
`;

const AvatarWrapper = styled(Avatar)`
  margin-right: 10px;
`;

const AccountNameWrapper = styled.div`
  text-transform: capitalize;
`;
