import type { Amount } from "../../../core/amount/amount";
import type { TransactionGeolocationManager } from "../../../core/geolocalisation/transaction-geolocalisation-manager";
import { runAfterInteractions } from "../../../core/interaction/interaction-manager";
import { Observable } from "../../../utils/observable";
import type { Account } from "../../account/account";
import type { AccountManager } from "../../account/account-manager";
import { accountsAffectedByTransaction } from "../../accounting-transaction/transaction/accounts-affected-by-transaction";
import type { RefundTransactionReason, Transaction } from "../../accounting-transaction/transaction/transaction";
import type { TransactionsManager } from "../../accounting-transaction/transaction/transactions-manager";
import type { Client } from "../../client/client";
import type { FeaturesManager } from "../../features/features-manager";
import type { PincodeSubmission } from "../../pincode/pincode";
import type { AccountOrRecipient, PaymentAddress, PaymentNetwork } from "../customer-instruction";
import { ServiceLevel } from "../customer-instruction";
import type { ConfirmationMode } from "../transaction-request";
import type { TransferService } from "./transfer-service";

export class TransferManager {
  public constructor(
    private transferService: TransferService,
    private geolocationManager: TransactionGeolocationManager,
    private accountManager: AccountManager,
    private transactionsManager: TransactionsManager,
    private featuresManager: FeaturesManager,
  ) {}
  public paymentNetworks = new Observable<PaymentNetwork[]>([]);

  public async startTransfer(recipientId: string, amount: Amount, label: string | undefined = undefined) {
    const location = await this.geolocationManager.updatePosition();
    return await this.transferService.startTransfer(recipientId, amount, label, location);
  }

  public async confirmTransfer(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    label?: string,
    pincode?: PincodeSubmission,
  ) {
    const location = this.geolocationManager.getPosition();
    const result = await this.transferService.confirmTransfer(
      confirmationMode,
      recipientId,
      amount,
      label,
      pincode,
      location,
    );
    this.refreshAccountAndTransactions(result.metadata.transaction);
    return result;
  }

  public async startPayout(
    recipientId: string,
    amount: Amount,
    srcAccountId: string,
    purpose: string | undefined = undefined,
  ) {
    const location = await this.geolocationManager.updatePosition();
    return await this.transferService.startPayout(recipientId, amount, srcAccountId, purpose, location);
  }

  public async confirmPayout(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    srcAccountId: string,
    purpose: string | undefined = undefined,
    pincode?: PincodeSubmission,
  ) {
    const location = this.geolocationManager.getPosition();
    const result = await this.transferService.confirmPayout(
      confirmationMode,
      recipientId,
      amount,
      srcAccountId,
      purpose,
      pincode,
      location,
    );
    this.refreshAccountAndTransactions(result.metadata.transaction);
    return result;
  }

  public async startCashTransfer(recipientId: string, amount: Amount, label: string | undefined = undefined) {
    const location = await this.geolocationManager.updatePosition();
    return await this.transferService.startCashTransfer(recipientId, amount, label, location);
  }

  public async confirmCashTransfer(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    label?: string,
    pincode?: PincodeSubmission,
  ) {
    const location = this.geolocationManager.getPosition();
    const result = await this.transferService.confirmCashTransfer(
      confirmationMode,
      recipientId,
      amount,
      label,
      pincode,
      location,
    );
    this.refreshAccountAndTransactions(result.metadata.transaction);
    return result;
  }

  private refreshAccountAndTransactions(transaction: Transaction) {
    runAfterInteractions(async () => {
      const accountIds = accountsAffectedByTransaction(transaction);
      await this.accountManager.refresh();
      await this.transactionsManager.refresh(accountIds);
    });
  }

  // SIMPLE TRANSFER

  public async startSimpleTransfer(amount: Amount, phoneNumber: string, label: string | undefined = undefined) {
    const location = await this.geolocationManager.updatePosition();
    return await this.transferService.startSimpleTransfer(amount, phoneNumber, label, location);
  }

  public async confirmSimpleTransfer(
    confirmationMode: ConfirmationMode,
    amount: Amount,
    phoneNumber: string,
    label?: string,
    pincode?: PincodeSubmission,
  ) {
    const location = this.geolocationManager.getPosition();
    const result = await this.transferService.confirmSimpleTransfer(
      confirmationMode,
      amount,
      phoneNumber,
      label,
      pincode,
      location,
    );
    this.refreshAccountAndTransactions(result.metadata.transaction);
    return result;
  }

  // CUSTOMER INSTRUCTIONS
  public async getPaymentNetworks(): Promise<PaymentNetwork[]> {
    if (!this.featuresManager.features.get().paymentNetwork || !this.featuresManager.features.get().paymentContract) {
      return [];
    }
    try {
      const response = await this.transferService.getPaymentNetworks();
      // TODO: remove this filter when the backend is fixed
      const paymentNetworks = response.filter((item) => item.activated && item.serviceLevel !== ServiceLevel.ON_US);
      this.paymentNetworks.set(paymentNetworks);
      return paymentNetworks;
    } catch (error) {
      this.paymentNetworks.set([]);
      throw error;
    }
  }
  public async startCustomerInstruction(
    paymentNetwork: PaymentNetwork,
    amount: Amount,
    sourceAccount: Account,
    destinationAccountOrRecipient: AccountOrRecipient,
    pincode?: PincodeSubmission,
    label?: string,
    creditorAddress?: PaymentAddress,
    foreignAmount?: Amount | null,
  ) {
    if (!this.featuresManager.features.get().customerInstructionInitiation) {
      throw "Customer instruction initiation is not enabled";
    }
    return await this.transferService.startCustomerInstruction(
      paymentNetwork,
      amount,
      sourceAccount,
      destinationAccountOrRecipient,
      pincode,
      label,
      creditorAddress,
      foreignAmount,
    );
  }

  public async submitCustomerInstruction(strongAuthenticationReference: string, id: string | number) {
    try {
      return await this.transferService.submitCustomerInstruction(id, strongAuthenticationReference);
    } catch (error) {
      throw error;
    }
  }

  public async submitCreditTransferSinglePayment(
    businessProcessId: string,
    client: Client,
    refundReason: RefundTransactionReason,
  ) {
    try {
      const res = await this.getPaymentTransaction(businessProcessId);
      const paymentRTransaction = {
        paymentRTransactionAmountInformation: {
          returnedAmount: res.paymentTransactionAmountInformation.instructedAmount,
          chargeBearer: res.paymentTransactionAmountInformation.chargeBearer,
          chargesAmount: res.paymentTransactionAmountInformation.chargesAmount,
        },
        paymentRTransactionInformation: {
          paymentRTransactionIdentification: {
            originalTransactionId: businessProcessId,
            relatedTransactionInternalId: businessProcessId,
          },
          paymentRTransactionReturnInformation: {
            originator: {
              value: `${client.firstName} ${client.lastName}`,
              type: "name",
            },
            reason: refundReason,
          },
        },
      };
      const submitResponse = await this.transferService.submitCreditTransferSinglePayment(res, paymentRTransaction);
      return submitResponse;
    } catch (e) {
      throw e;
    }
  }

  private async getPaymentTransaction(id: string | number) {
    if (!this.featuresManager.features.get().paymentInstructionView) {
      throw "Cannot get payment instruction. Feature not enabled";
    }
    return await this.transferService.getPaymentTransaction(id);
  }
}
