import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import type { Intl } from "../../../../shared/core/i18n/use-intl";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import {
  accountManager,
  authExecutor,
  featuresManager,
  transactionSearchManager,
  trustManager,
} from "../../../../shared/core/service/services";
import { useAccounts } from "../../../../shared/domains/account/use-accounts";
import { TimeRange } from "../../../../shared/domains/accounting-transaction/search/timerange";
import type { AccountingTransaction } from "../../../../shared/domains/accounting-transaction/transaction/transaction";
import { useTransactionFilter } from "../../../../shared/domains/accounting-transaction/transaction/use-transaction-filter";
import { useTransactions } from "../../../../shared/domains/accounting-transaction/transaction/use-transactions";
import type { AuthorizationHold } from "../../../../shared/domains/authorization-hold/authorization-hold";
import { useAuthorizationHolds } from "../../../../shared/domains/authorization-hold/use-authorization-hold";
import { OnboardingStatus } from "../../../../shared/domains/onboarding/onboarding";
import { useOnboardingFile } from "../../../../shared/domains/onboarding/use-onboarding";
import { useLoadMoreDebounce } from "../../../../shared/utils/list";
import { useObservable } from "../../../../shared/utils/observable";
import { useAsyncEffect } from "../../../../shared/utils/utils";
import {
  ACCOUNT_TRANSACTION_DETAILS_MODAL_ID,
  AUTHORIZATION_HOLD_DETAILS_MODAL_ID,
  TRUST_CHOICE_MODAL_ID,
} from "../../../core/modal/modal-id";
import { Path } from "../../../core/routing/path";
import { useClient } from "../../../domain/authentication/use-client";
import { useRTL } from "../../../domain/language/use-rtl";
import { TertiaryButton, TertiaryLink } from "../../common/buttons/tertiary-button";
import { SectionCarousel } from "../../common/carousel/section-carousel";
import { Modal } from "../../common/modal/modal";
import { PageHeader } from "../../common/nav/page-header";
import { PageSectionTitle } from "../../common/nav/page-section-title";
import { MainColorSpinner } from "../../common/spinner";
import { theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { AccountTile, SkeletonAccountTile } from "./components/account-tile";
import { AccountTransactionDetailsModal } from "./components/accounting-transaction-details-modal";
import { AuthorizationHoldDetailsModal } from "./components/authorization-hold-details-modal";
import { FilterBar } from "./components/filter-bar";
import { TransactionAccordion } from "./components/transaction-accordion";
import { TransactionsSectionView } from "./components/transactions-section";
import { TrustChoiceModal } from "./trust-modal/trust-modal";
import { ACCOUNT_QUERY_PARAM, useSelectedAccount } from "./utils/use-selected-account";

export default function AccountTransactionsScreen() {
  const { client } = useClient();
  const accounts = useAccounts();
  const features = useObservable(featuresManager.features);
  const transactionDirection = useObservable(transactionSearchManager.directionSelection);
  const timerangeSelection = useObservable(transactionSearchManager.timeRangeSelection);
  const [startDate, endDate] = timerangeSelection.bounds;
  const [startPeriodLabel, setStartPeriodLabel] = useState<string>();
  const [nextPeriodLabel, setNextPeriodLabel] = useState<string>();
  const isConnecting = useObservable(authExecutor.isConnecting);
  const loading = useObservable<boolean>(accountManager.loading);
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(true);
  const [onboardingIsPendingVerification, setOnboardingIsPendingVerification] = useState<boolean>(false);
  const refreshing = useObservable(accountManager.refreshing);
  const accountsLoading = loading || refreshing;
  const [focusedAccount, setFocusedAccount] = useSelectedAccount(accounts);
  const { filters } = useTransactionFilter(focusedAccount);
  const transactions = useTransactions(focusedAccount, transactionDirection, filters);
  const authorizationHolds = useAuthorizationHolds(focusedAccount);
  const { isRTL } = useRTL();
  const { onboardingFile, needOnboardingFileRedirect, loading: onboardingFileLoading } = useOnboardingFile();
  const history = useHistory();

  const showTrustModal = () => {
    Modal.present(TRUST_CHOICE_MODAL_ID, () => <TrustChoiceModal />, {
      backdropColor: "#f4f4f4",
      backdropOpacity: 1,
      canBeDismissed: false,
    });
  };

  useAsyncEffect(async () => {
    if (isConnecting) {
      const shouldShowTrustModal = !(await trustManager.isValid());
      if (shouldShowTrustModal) {
        showTrustModal();
      } else {
        await trustManager.checkTrusted();
      }
    }
  }, [isConnecting]);

  useEffect(() => {
    if (!onboardingFileLoading) {
      if (needOnboardingFileRedirect) {
        history.push(Path.OnboardingFile);
      }
      setIsGlobalLoading(false);
    }
  }, [onboardingFileLoading]);

  useEffect(() => {
    setOnboardingIsPendingVerification(onboardingFile?.status === OnboardingStatus.PENDING_VERIFICATION);
  }, [onboardingFile]);

  const intl = useIntl();
  const { formatMessage } = intl;

  const sections = useMemo(() => {
    if (transactions.loading || !transactions.data) {
      return [];
    }
    const transactionsGroupedByDay = groupTransactionsByDay(intl, transactions.data);
    const transactionsSortedByDate = Object.values(transactionsGroupedByDay).sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    );
    return transactionsSortedByDate;
  }, [transactions.data, transactions.loading, intl]);

  const loadMoreDebounce = useLoadMoreDebounce();
  const loadMore = useCallback(
    () =>
      loadMoreDebounce(
        transactions.loadMore,
        transactions.loading || transactions.loadingMore,
        transactions.canLoadMore,
      ),
    [transactions, loadMoreDebounce],
  );

  const fetchPreviousPeriod = () => {
    /** Account transactions must be fetched by period of 3 months. */
    const newStartDate = moment(startDate);
    const newEndDate = moment(startDate); //clones of startDate
    transactionSearchManager.setTimeRange(TimeRange.Other, [
      newStartDate?.subtract(3, "M"),
      newEndDate?.subtract(1, "days"),
    ]);
  };

  const fetchNextPeriod = () => {
    /** Account transactions must be fetched by period of 3 months. */
    const newStartDate = moment(endDate);
    const newEndDate = moment(endDate); //clones of startDate
    transactionSearchManager.setTimeRange(TimeRange.Other, [
      newStartDate?.add(1, "days"),
      newEndDate?.add(3, "M").add(1, "days"),
    ]);
  };

  useEffect(() => {
    if (startDate) {
      setStartPeriodLabel(
        `${moment(startDate)?.subtract(3, "M").format("DD/MM/YYYY")} \u2192 ${moment(startDate)
          ?.subtract(1, "days")
          .format("DD/MM/YYYY")}`,
      );
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setNextPeriodLabel(
        `${moment(endDate)?.add(1, "days").format("DD/MM/YYYY")} \u2192 ${moment(endDate)
          ?.add(3, "M")
          .add(1, "days")
          .format("DD/MM/YYYY")}`,
      );
    }
  }, [endDate]);

  const isNextPeriodInTheFuture = () => moment(endDate).isAfter() || moment(endDate)?.add(3, "M").isAfter();

  return (
    <>
      {onboardingIsPendingVerification ? (
        <StyledGlobalContainer>
          <StyledMessageCard>
            <StyledTitle>{formatMessage("accountPendingVerification.title")}</StyledTitle>
            <StyledMessage>{formatMessage("accountPendingVerification.subtitle")}</StyledMessage>
            <UnderlinedButton onClick={() => window.location.reload()}>
              {formatMessage("accountPendingVerification.retry")}
            </UnderlinedButton>
          </StyledMessageCard>
        </StyledGlobalContainer>
      ) : (
        <>
          <AccountsSection>
            <CarouselHeader>
              <AccountsSectionTitle $isRTL={isRTL}>{formatMessage("accountsList.title")}</AccountsSectionTitle>
              {accounts.length > 0 && (
                <GoToAllAccountsLink
                  to={
                    focusedAccount
                      ? `${Path.AllAccounts}?${ACCOUNT_QUERY_PARAM}=${focusedAccount.id}`
                      : Path.AllAccounts
                  }
                >
                  {formatMessage("accountsList.showAllAccountsLink")}
                </GoToAllAccountsLink>
              )}
            </CarouselHeader>
            {!accountsLoading && accounts.length === 0 ? (
              <NoAccountAvailable>{formatMessage("accountsList.noAccountAvailable")}</NoAccountAvailable>
            ) : (
              <SectionCarousel
                items={accounts}
                loading={accountsLoading}
                getItemWidth={(isMobile) =>
                  isMobile ? UIConstants.ACCOUNT_TILE_WIDTH_MOBILE : UIConstants.ACCOUNT_TILE_WIDTH_NOT_MOBILE
                }
                renderItem={(account, index) => (
                  <AccountTile
                    key={account.id + index}
                    onAccountClick={(account) => setFocusedAccount(account)}
                    isActive={account.id === focusedAccount?.id ?? index === 0}
                    account={account}
                    owner={client}
                    withDetail
                  />
                )}
                renderSkeleton={(index) => <SkeletonAccountTile key={index} $isRTL={isRTL} />}
              />
            )}
          </AccountsSection>
          {!!focusedAccount && (
            <TransactionsSection>
              <PageHeader>
                <PageSectionTitle>{formatMessage("accountTransactions.title")}</PageSectionTitle>
                <FilterBar account={focusedAccount} isRTL={isRTL} />
              </PageHeader>
              {transactions.data.length === 0 && authorizationHolds.data.length === 0 ? (
                transactions.loading || authorizationHolds.loading ? (
                  <StyledBlueSpinner key="spinner" />
                ) : (
                  <NoTransactionAvailable>
                    {formatMessage("accountTransactions.emptyTransactionList")}
                  </NoTransactionAvailable>
                )
              ) : (
                <>
                  {transactions.loading ? (
                    <StyledBlueSpinner style={{ marginBottom: 8 }} key="spinner" />
                  ) : (
                    <>
                      {transactions.unjustifiedTransactions2.items && features.manageCategorizationSpendings && (
                        <StyledTransactionAccordion
                          data={[
                            ...new Map([...transactions.unjustifiedTransactions2.items].map((v) => [v.id, v])).values(),
                          ]} //removing duplicate values which are returned in unjustified and in unqualified transactions
                          total={transactions.unjustifiedTransactions2.total}
                          transactionType={"unjustifiedTransactions2"}
                          onTransactionClick={(transaction: AccountingTransaction) => {
                            focusedAccount &&
                              Modal.present(
                                ACCOUNT_TRANSACTION_DETAILS_MODAL_ID,
                                () => (
                                  <AccountTransactionDetailsModal transaction={transaction} account={focusedAccount} />
                                ),
                                {
                                  canBeDismissed: false,
                                },
                              );
                          }}
                        />
                      )}
                      {transactions.unjustifiedTransactions2.items && features.manageCategorizationSpendings && (
                        <StyledTransactionAccordion
                          data={[
                            ...new Map([...transactions.unqualifiedTransactions.items].map((v) => [v.id, v])).values(),
                          ]} //removing duplicate values which are returned in unjustified and in unqualified transactions
                          total={transactions.unqualifiedTransactions.total}
                          transactionType={"unqualifiedTransactions"}
                          onTransactionClick={(transaction: AccountingTransaction) => {
                            focusedAccount &&
                              Modal.present(
                                ACCOUNT_TRANSACTION_DETAILS_MODAL_ID,
                                () => (
                                  <AccountTransactionDetailsModal transaction={transaction} account={focusedAccount} />
                                ),
                                {
                                  canBeDismissed: false,
                                },
                              );
                          }}
                        />
                      )}
                    </>
                  )}
                  {transactions.loading && <StyledSeparator />}

                  <StyledTransactionAccordion
                    data={authorizationHolds.data}
                    onAuthorizationHoldClick={(transaction: AuthorizationHold) => {
                      Modal.present(
                        AUTHORIZATION_HOLD_DETAILS_MODAL_ID,
                        () => <AuthorizationHoldDetailsModal authorizationHold={transaction} />,
                        {
                          canBeDismissed: false,
                        },
                      );
                    }}
                  />
                  <StyledInfiniteScroll
                    loadMore={loadMore}
                    hasMore={transactions.canLoadMore}
                    loader={<StyledBlueSpinner key="transaction-loader" />}
                  >
                    {sections.map((section) => (
                      <TransactionsSectionByDate key={section.label} account={focusedAccount} section={section} />
                    ))}
                  </StyledInfiniteScroll>
                  {!transactions.canLoadMore && !transactions.loading && (
                    <StyledButtonContainer>
                      <StyledButtonContainerItem>
                        <TertiaryButton onClick={() => fetchPreviousPeriod()}>
                          {formatMessage("accountTransactions.previousPeriodButtonLabel")}
                        </TertiaryButton>
                        {startPeriodLabel?.length > 0 && <StyledPeriodLabel>{startPeriodLabel}</StyledPeriodLabel>}
                      </StyledButtonContainerItem>
                      {!isNextPeriodInTheFuture() && (
                        <StyledButtonContainerItem>
                          <TertiaryButton onClick={() => fetchNextPeriod()}>
                            {formatMessage("accountTransactions.nextPeriodButtonLabel")}
                          </TertiaryButton>
                          {nextPeriodLabel?.length > 0 && <StyledPeriodLabel>{nextPeriodLabel}</StyledPeriodLabel>}
                        </StyledButtonContainerItem>
                      )}
                    </StyledButtonContainer>
                  )}
                </>
              )}
            </TransactionsSection>
          )}
          {isGlobalLoading && (
            <StyledGlobalLoader>
              <MainColorSpinner size="60" />
            </StyledGlobalLoader>
          )}
        </>
      )}
    </>
  );
}

const StyledGlobalLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.gray[100]};
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AccountsSection = styled.section`
  align-self: stretch;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const TransactionsSection = styled(AccountsSection)`
  align-self: stretch;
  min-height: 500px;
`;

const AccountsSectionTitle = styled(PageSectionTitle)<{ $isRTL: boolean }>`
  margin-right: ${(props) => (props.$isRTL ? 0 : 10)}px;
  margin-left: ${(props) => (props.$isRTL ? 10 : 0)}px;
`;

const TransactionsSectionByDate = styled(TransactionsSectionView)`
  &:first-of-type {
    margin-top: 25px;
  }
  &:not(:last-of-type) {
    margin-bottom: 33px;
  }
`;

function groupTransactionsByDay(intl: Intl, transactions: AccountingTransaction[]) {
  return transactions.reduce<{
    [key: string]: {
      label: string;
      data: AccountingTransaction[];
      date: string;
    };
  }>((groups, transaction) => {
    const dateGroup = intl.formatDate(transaction.date, { year: "numeric", month: "numeric", day: "numeric" });
    if (groups[dateGroup]) {
      groups[dateGroup].data.push(transaction);
    } else {
      groups[dateGroup] = {
        label: intl.formatRelativeDate(transaction.date),
        date: transaction.date,
        data: [transaction],
      };
    }
    return groups;
  }, {});
}

const CarouselHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

const GoToAllAccountsLink = styled(TertiaryLink)`
  text-align: center;
`;

const NoAccountAvailable = styled.span`
  ${theme.text};
  margin-top: 20px;
  margin-bottom: 40px;
`;

const NoTransactionAvailable = styled(NoAccountAvailable)``;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledBlueSpinner = styled(MainColorSpinner)`
  margin: 40px auto 0 auto;
`;

const StyledSeparator = styled.div`
  margin-bottom: 12px;
`;

const StyledTransactionAccordion = styled(TransactionAccordion)`
  align-self: stretch;
  margin-bottom: 20px;
`;

const StyledButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledButtonContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledPeriodLabel = styled.div`
  ${theme.text};
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  color: ${theme.colors.gray[500]};
`;

const StyledGlobalContainer = styled.div`
  height: 100%;
  margin-top: -20px;
  background-color: ${theme.colors.gray[100]};
`;

const StyledMessageCard = styled.div`
  background-color: #ffecd1;
  border-radius: 13px;
  padding: 16px;
`;

const StyledMessage = styled.div`
  ${theme.text};
  font-size: 14px;
  margin-top: 10px;
  color: ${theme.colors.gray[700]};
`;

const UnderlinedButton = styled.button<{ disabled?: boolean }>`
  ${theme.bodyGreyRegular}
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
  color: #000000;
  font-size: 13px;
  text-decoration: underline;
  margin-top: 16px;
  &:hover {
    text-decoration: none;
  }
  ${(props) =>
    props.disabled &&
    `
        pointer-events: none;
        opacity: 0.5;
    `}
`;
const StyledTitle = styled.div`
  ${theme.bodyBlackBold}
  color: '#000000';
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 8px;
`;
