import styled from "styled-components";

import { UIConstants } from "../../styles/uiConstants";

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;
